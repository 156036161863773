<!--表单-->
<template>
  <ve-line :grid="grid" :settings="settings" :data="data" :colors="color" :xAxis="xAxis" :yAxis="yAxis" resizeable></ve-line>
</template>
<style scoped>
</style>
<script>

const defaultQuery = {
  orderBys: 'crawlTime|asc',
  dayOffset: undefined,
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  param: {
    authorId: undefined
  },
  start: 0,
  limit: 1000
}

export default {
  components: { },
  props: {
    authorId: {
      type: String,
      required: true
    },
    dayQuery: {
      type: Object,
      required: true,
      default: () => {}
    },
    grid: {
      type: Object,
      default: () => ({left: '2%', right: '2%'})
    }
  },
  data() {
    return {
      query: _.cloneDeep(defaultQuery),
      data: {
        columns: ['gapDate', 'fansCount', 'gapFansCount'],
        rows: []
      },
      color: ['#5793f3', '#faad14', '#675bba'],
      xAxis: {
        type: 'time',
        splitLine: {show: false},
        axisTick: {show: false},
        boundaryGap: false,
        minInterval: 3600 * 24 * 1000
      },
      yAxis: [{
        scale: true,
        splitLine: {show: false},
        axisLine: {show: true, lineStyle: { color: '#5793f3' }},
        boundaryGap: [0, '50%']
      },{
        scale: true,
        splitLine: {show: false},
        axisLine: {show: true, lineStyle: { color: '#faad14' }},
        boundaryGap: [0, '50%']
      }],
      settings: {
        labelMap: {
          'fansCount': '粉丝总量',
          'gapFansCount': '粉丝增量'
        },
        scale: [true, true],
        axisSite: { right: ['gapFansCount'] },
        xAxisType: 'time',
        yAxisName: ['总量(人)', '增量(人/天)']
      }
    }
  },
  computed: {
  },
  methods: {
    load(reset = false) {
      Object.assign(this.query, { param: { authorId: this.authorId } }, this.dayQuery)
      this.$api.core.AuthorService.queryStat(this.query).then(res => {
        res.data.forEach(t => {
          t.fansCount = t.fansCountTotal
          t.gapFansCount = t.fansCountTotalDi
          t.gapDate = (t.statisticsDay || '').substr(0, 10)
        })
        this.data.rows = res.data || []
      })
    },
    init(options = {}) {
      this.load();
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
