import request from '@/utils/request';

const baseURL = "/content-center";


export default {
  query (data) {
    return request({
      url: '/base/user_info/query',
      method: 'POST',
      data
    });
  },
  save (data) {
    return request({
      url: '/base/user_info/save',
      method: 'POST',
      data
    });
  },
  get (id) {
    return request({
      url: '/base/user_info/get/' + id,
      method: 'GET'
    });
  },
  getUrl (params) {
    return request({
      baseURL,
      url: '/v1/video/page-info',
      method: 'get',
      params
    });
  }
};
