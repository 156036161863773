<!--表单-->
<template>
  <div>
    <day-span v-model="daySpan" @change="load"></day-span>
    <a-row type="flex" style="padding: 16px 0">
      <a-col flex="auto">
        <v-statistics :columns="statisticColumns" :data="statisticData"></v-statistics>
      </a-col>
    </a-row>
    <fans-line ref="fansLine" :author-id="authorId" :day-query="dayQuery"></fans-line>
  </div>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>
import DaySpan from "@/components/widgets/day-span"
import FansLine from "./FansLine"

const defaultQuery = {
  orderBys: 'fansCount|desc,authorId|desc',
  param: {
  },
  groupId: undefined
}
const defaultStatQuery = {
  dayOffset: undefined,
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  param: {
    authorId: undefined
  },
  start: 0,
  limit: 100
}

export default {
  components: { DaySpan, FansLine },
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      query: _.cloneDeep(defaultQuery),
      loading: false,
      loadingSummary:false,
      pagination: { pageSize: 10 },
      daySpan: {
        dayOffset: 7,
        dayRange: []
      },
      statisticColumns: [
        { title: '新增粉丝', dataIndex: 'gapFansCount', formatter: ({ value }) => this.$dict.humanReadable(value) }
      ],
      statisticData: {}
    }
  },
  computed: {
    dayQuery() {
      return {
        dateRanges: this.daySpan.dayRange.length ? {
          dateRange: {
            startDate: this.daySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.daySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.daySpan.dayRange.length ? undefined : (this.daySpan.dayOffset || 7)
      }
    }
  },
  methods: {
    findGap(){
      const statQuery = Object.assign(_.cloneDeep(defaultStatQuery), { param: { authorId: this.authorId } }, this.dayQuery)
      this.$api.core.AuthorService.findGap(statQuery).then(res => {
        this.statisticData = res[0] || {}
      })
    },
    load() {
      this.findGap()
      this.$nextTick(() => {
        this.$refs.fansLine.$emit('init')
      })
    },
    init(options = {}) {
      this.load();
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
