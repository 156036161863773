<template>
  <section class="page">
    <a-card style="margin-bottom: 16px;">
      <div class="info">
        <div class="info__hd">
          <div class="author-info">
            <div class="author-info__avatar">
              <a-avatar
                  :src="author.avatar"
                  :size="70"
                  icon="user"
              />
              <a class="join-btn" @click="handleJoinGroup">
                加入分组
              </a>
            </div>
            <div class="author-info__content">
              <div class="author-name">
                <a :href="authorHomeUrl" target="_blank">
                  播主昵称：{{ author.nickname || "-" }}
                </a>
                <a-popover v-if="codeUrl">
                  <a-icon class="icon-qrcode" type="qrcode"/>
                  <template slot="content">
                    <img :src="codeUrl" alt="">
                    <p style="text-align: center;">
                      请使用{{ platform === 1 ? "抖音" : "快手" }}App进行扫码
                    </p>
                  </template>
                </a-popover>
              </div>
              <div class="author-code mt-6">
                <i :class="[
                  'icon',
                  {
                    'icon-douyin': platform === 1,
                    'icon-kuaishou': platform === 2
                  }
                ]"/>
                <a :href="authorHomeUrl" target="_blank">
                  {{ platform === 1 ? "抖音" : "快手" }}号：{{ author.code || "-" }}
                </a>
              </div>
              <div class="author-dealer mt-4">
                <a
                    v-if="author.dealerId"
                    :href="`${loacationOrigin}/dealerManage/dealerDetail?dealer_id=${author.dealerId}`"
                    target="_blank"
                >
                  经销商：{{ author.dealerName || "-" }}({{ author.dealerStoreCode || "-" }})
                </a>
                <span v-else>
                  经销商：-
                </span>
              </div>
              <div class="author-principal mt-4">
                主体：{{ author.principalNames || "-" }}
              </div>
              <div class="author-auth mt-4">
                <span style="margin-right:10px">互动数据(P2):</span>
                <a-tag v-if="author.p2OauthStatus == 0" color="red"> 未授权</a-tag>
                <a-tag v-if="author.p2OauthStatus == 1 || author.p2OauthStatus == 3" color="green"> 已授权</a-tag>
                <a-tag v-if="author.p2OauthStatus == 2" color="red">
                  授权过期
                </a-tag>
                <a-tag v-if="author.p2OauthStatus == 5 || author.p2OauthStatus == 6" color="red">
                  --
                </a-tag>
                <a-tag v-if="author.p2OauthStatus == 4" color="red"> 处理中</a-tag>

                <a-divider type="vertical"/>
                <span style="margin-right:10px">直播数据(P1):</span>
                <a-tag v-if="author.p1OauthStatus == 2" color="red"> 授权过期</a-tag>
                <a-tag v-if="author.p1OauthStatus == 1" color="green"> 已授权</a-tag>
                <a-tag v-if="author.p1OauthStatus == 0" color="red"> 未授权</a-tag>
              </div>
            </div>
          </div>
        </div>
        <div class="info__bd">
          <div class="update-time">
            数据更新时间：{{ author.mtime || "-" }}
          </div>
          <div class="author-data">
            <div class="data-item">
              <div class="data-item__label">粉丝数量</div>
              <div class="data-item__value">
                {{ author.fansCountTotal | humanReadable }}
              </div>
            </div>
            <div class="data-item">
              <div class="data-item__label">获赞量</div>
              <div class="data-item__value">
                {{ author.likeCountTotal | humanReadable }}
              </div>
            </div>
            <div class="data-item">
              <div class="data-item__label">作品数</div>
              <div class="data-item__value">
                <a
                    :href="`${loacationOrigin}/mediaCenter/awemeList?platform=${platform}&code=${author.code}`"
                    target="_blank"
                >
                  {{ author.awemeCountTotal | humanReadable }}
                </a>
              </div>
            </div>
            <div class="data-item">
              <div class="data-item__label">模板作品</div>
              <div class="data-item__value">
                <a
                    :href="`${loacationOrigin}/mediaCenter/awemeList?platform=${platform}&code=${author.code}&useModule=1`"
                    target="_blank"
                >
                  {{ author.moduleAwemeCount | humanReadable }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <a-page-header
          :extra="`数据更新时间：${author.mtime || '-'}`"
          style="padding: unset"
      /> -->
      <!-- <a-row type="flex">
        <a-col>
          <author-card
              :value="author"
              :dataType="$root.dataType"
              :link="false"
              favorite
              @favorite="favorite"
          ></author-card>
        </a-col>
        <a-col flex="auto" style="margin: auto">
          <v-statistics
              :columns="statisticColumns"
              :data="author"
              flex="0 1 150px"
              justify="center"
          ></v-statistics>
        </a-col>
      </a-row> -->
      <!-- <a-row style="padding-top:10px">
        <a-col> -->
      <!-- <span style="margin-right:10px">互动数据（P2）:</span>
      <a-tag v-if="open_status == 0" color="red"> 未授权</a-tag>
      <a-tag v-if="open_status == 1 || open_status == 3" color="green"> 已授权</a-tag>
      <a-tag v-if="open_status == 2" color="red">
        授权过期
      </a-tag>
      <a-tag v-if="open_status == 5 || open_status == 6" color="red">
        --
      </a-tag>
      <a-tag v-if="open_status == 4" color="red"> 处理中</a-tag>
      <a-divider type="vertical"/>
      <span style="margin-right:10px">直播数据（P1）:</span>
      <a-tag v-if="service_status == 2" color="red"> 授权过期</a-tag>
      <a-tag v-if="service_status == 1" color="green"> 已授权</a-tag>
      <a-tag v-if="service_status == 0" color="red"> 未授权</a-tag> -->
      <!--<a-divider type="vertical"/>-->
      <!--<router-link :to="{ name: 'authorAuth', params: { authorId: authorId } }" target="_blank">编辑授权-->
      <!--</router-link>-->

      <!-- </a-col>
    </a-row> -->
    </a-card>

    <a-card>
      <div v-sticky="{ zIndex: 1000, top: 0, fixTarget: '#gaManageTab' }">
        <a-tabs v-model="activeTab" @change="onTabChange" id="gaManageTab">
          <a-tab-pane v-if="platform === 1" key="awemeData" tab="视频数据总览"></a-tab-pane>
          <a-tab-pane key="awemeList" tab="作品列表"></a-tab-pane>
          <a-tab-pane key="liveList" tab="直播列表"></a-tab-pane>
          <a-tab-pane v-if="platform === 1" key="dataList" tab="数据对比"></a-tab-pane>
          <a-tab-pane v-if="platform === 1" key="fansPortrait" tab="粉丝画像"></a-tab-pane>
          <!-- todo -->
          <!-- <a-tab-pane key="fansAnalysis" tab="粉丝数据">
          </a-tab-pane> -->
        </a-tabs>
      </div>
      <aweme-data
          v-if="activeTab === 'awemeData'"
          ref="awemeData"
          :author-id="authorId"
      />

      <aweme-list
          v-if="activeTab === 'awemeList'"
          ref="awemeList"
          :platform="platform"
          :author-id="authorId"
      />

      <live-list
          v-if="activeTab === 'liveList'"
          ref="liveList"
          :platform="platform"
          :author-id="authorId"
          :type="activeTab"
          :principalIds="author.principalIds"
          :p9OauthStatus="author.p9OauthStatus"
      />

      <data-list
          v-if="activeTab === 'dataList'"
          ref="dataList"
          :author-id="authorId"
          :type="activeTab"
      />

      <fans-analysis
          v-if="activeTab === 'fansAnalysis'"
          ref="fansAnalysis"
          :author-id="authorId"
      />

      <fans-portrait
          v-if="activeTab === 'fansPortrait'"
          ref="fansPortrait"
          :author-id="authorId"
      />
    </a-card>

    <favorite-groups-model
        :type="1"
        ref="favoriteGroupsModel"
    />

    <JoinAuthorGroupModal
        ref="joinAuthorGroupModal"
        v-model="joinAuthorGroupVisible"
        :form="joinAuthorGroupForm"
        :group-list="joinAuthorGroupList"
        :confirm-loading="joinAuthorGroupLoading"
        @add-group="addAuthorGroupVisible = true"
        @confirm="handleJoinAuthorGroupConfirm"
    />

    <AddAuthorGroupModal
        ref="addAuthorGroupModal"
        v-model="addAuthorGroupVisible"
        :form="addAuthorGroupForm"
        :confirm-loading="addAuthorGroupLoading"
        @confirm="handleAddAuthorGroupConfirm"
    />
  </section>
</template>

<script>
import QRCode from "qrcode";
import AuthorCard from "@/components/widgets/author-card";
import FavoriteGroupsModel from "@/components/widgets/favorite-groups-model";
import AwemeData from "./AwemeData";
import AwemeList from "./AwemeList";
import LiveList from "./LiveList";
import FansAnalysis from "./FansAnalysis";
import DataList from "./DataList";
import FansPortrait from "./fans_portrait";
import JoinAuthorGroupModal from "@/components/JoinAuthorGroupModal";
import AddAuthorGroupModal from "@/components/AddAuthorGroupModal";
import _ from "lodash";
import authorApi from "@/api/author";

export default {
  components: {
    AuthorCard,
    FavoriteGroupsModel,
    AwemeData,
    AwemeList,
    LiveList,
    FansAnalysis,
    DataList,
    FansPortrait,
    JoinAuthorGroupModal,
    AddAuthorGroupModal
  },
  data() {
    return {
      platform: "",
      authorId: "",
      activeTab: "",
      author: {},
      codeUrl: "",
      loacationOrigin: location.origin,
      statisticColumns: [
        {
          title: "粉丝数量",
          dataIndex: "fansCountTotal",
          formatter: ({value}) => this.$dict.humanReadable(value),
        },
        {
          title: "获赞量",
          dataIndex: "likeCountTotal",
          formatter: ({value}) => this.$dict.humanReadable(value),
        },
        {
          title: "作品数",
          dataIndex: "awemeCountTotal",
          formatter: ({value}) => this.$dict.humanReadable(value),
        },
        {
          title: "模板作品",
          dataIndex: "moduleAwemeCount",
          formatter: ({value}) => this.$dict.humanReadable(value),
        },
      ],
      open_status: "",
      service_status: "",
      joinAuthorGroupVisible: false,
      joinAuthorGroupType: "",
      joinAuthorGroupForm: {
        authorList: [],
        groupIdList: []
      },
      joinAuthorGroupList: [],
      joinAuthorGroupLoading: false,
      addAuthorGroupVisible: false,
      addAuthorGroupForm: {
        groupType: 1,
        groupName: ""
      },
      addAuthorGroupLoading: false
    };
  },

  created() {
    const {authorId} = this.$route.params;
    const {platform} = this.$route.query;

    this.platform = Number(platform);
    this.authorId = authorId;
    this.platform === 1 && (this.activeTab = "awemeData");
    this.platform === 2 && (this.activeTab = "awemeList");
    this.fetchAuthorDetail();
  },
  // mounted() {
  //   this.$on("init", this.init);
  // },
  computed: {
    authorHomeUrl() {
      let url;

      switch(this.platform) {
        case 1:
          url = `https://www.douyin.com/user/${ this.author.secAuthorId }`;
          break;
        case 2:
          url = `https://www.kuaishou.com/profile/${ this.author.authorId }`;
          break;
      }

      return url;
    }
  },
  methods: {
    // 获取主播分析-主播概况
    async fetchAuthorDetail() {
      try {
        const {platform, authorId} = this;
        const params = {
          platformType: platform,
          authorId
        };
        const {code, data, message} = await authorApi.fetchAuthorDetail(params);

        if(code === 200) {
          this.author = data;
          // console.log(this.author, "this.author");
          this.codeUrl = this.generateQRCode(
              this.author.authorUrl
              // platform === 1 ?
              // `snssdk1128://user/profile/${this.author.authorId}` :
              // `ksnebula://profile/${this.author.authorId}`
          );
          this.$nextTick(() => {
            this.$refs[this.activeTab].$emit("init");
          });
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
      }
    },
    // 生成功二维码
    generateQRCode(url) {
      let opts = {
        type: "image/jpeg",
        margin: 2,
        width: 200,
        height: 200,
        color: {
          dark: "#000",
          light: "#FFF"
        },
        quality: 0.3,
        errorCorrectionLevel: "H"
      };
      let codeUrl = "";

      QRCode.toDataURL(url, opts, (err, url) => {
        if(err) throw err;

        codeUrl = url;
      });

      return codeUrl;
    },
    onTabChange(activeTab) {
      this.$nextTick(() => {
        this.$refs[activeTab].$emit("init");
      });
    },
    favorite() {
      this.$nextTick(() => {
        this.$refs.favoriteGroupsModel.$emit("init", {
          record: {...this.author},
        });
      });
    },
    load() {
      this.$api.core.AuthorService.get(this.authorId).then((res) => {
        this.author = res || {};
      });
    },
    // init(options = {}) {
    //   this.$route.query.activeTab &&
    //   (this.activeTab = this.$route.query.activeTab);
    //   let {authorId} = this.$route.params;
    //   this.$api.FavoriteService.findFavoriteBizStatu([authorId]).then((res) => {
    //     this.open_status = _.get(res, "open_status[0].oauth_status", 0);
    //     this.service_status = _.get(res, "service_status[0].status", 0);
    //   });
    //   this.load();
    //   this.onTabChange(this.activeTab);
    // },
    handleJoinGroup() {
      const {authorId, nickname, dealerCompanyName, code} = this.author;

      this.joinAuthorGroupVisible = true;
      this.joinAuthorGroupType = "single";
      this.joinAuthorGroupForm.authorList = [{
        platform: this.platform,
        authorId,
        nickname,
        code,
        verifyName: dealerCompanyName
      }];
      this.fetchMyAuthorGroupList();
    },
    // 获取我的播主分组列表
    async fetchMyAuthorGroupList() {
      try {
        const {code, data, message} = await authorApi.fetchMyAuthorGroupList();

        if(code === 200) {
          this.joinAuthorGroupList = data;
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
      }
    },
    handleJoinAuthorGroupConfirm() {
      this.joinAuthorGroup();
    },
    // 加入播主分组
    async joinAuthorGroup() {
      this.joinAuthorGroupLoading = true;
      try {
        const {authorList, groupIdList} = this.joinAuthorGroupForm;
        const params = {
          authorList,
          groupList: groupIdList.map(id => {
            const index = this.joinAuthorGroupList.findIndex(item => item.groupId === id);
            const group = this.joinAuthorGroupList[index];
            const {groupId, groupName, isCommon} = group;

            return {groupId, groupName, groupType: isCommon};
          })
        };
        const {code, message} = await authorApi.joinAuthorGroup(params);

        this.joinAuthorGroupLoading = false;

        if(code === 200) {
          this.joinAuthorGroupType === "single" && this.$message.success("已将该播主加入分组！");
          this.joinAuthorGroupVisible = false;
          this.$refs.joinAuthorGroupModal.resetForm();
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
        this.joinAuthorGroupLoading = false;
      }
    },
    handleAddAuthorGroupConfirm() {
      this.addAuthorGroup();
    },
    // 新建播主分组
    async addAuthorGroup() {
      this.addAuthorGroupLoading = true;
      try {
        const {groupType, groupName} = this.addAuthorGroupForm;
        const params = {
          groupType,
          groupName
        };

        groupType === 2 && (params.source = 2);

        const {code, message} = await authorApi.addAuthorGroup(params);

        this.addAuthorGroupLoading = false;

        if(code === 200) {
          this.$message.success("新建播主分组成功");
          this.addAuthorGroupVisible = false;
          this.$refs.addAuthorGroupModal.resetForm();
          this.fetchMyAuthorGroupList();
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
        this.addAuthorGroupLoading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.info {
  display: flex;
  justify-content: space-between;

  &__bd {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.author-info {
  display: flex;
  justify-content: flex-start;

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px 0 0;
    width: 70px;
  }

  .join-btn {
    margin: 8px 0 0;
  }

  .author-name {
    color: #000;
    font-size: 1.1rem;
    font-weight: bold;

    .icon-qrcode {
      margin: 0 0 0 8px;
      cursor: pointer;
    }
  }

  .author-code {
    display: flex;
    align-items: center;

    .icon {
      display: inline-block;
      margin: 0 8px 0 0;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

      &-douyin {
        background-image: url("../../../assets/icon/icon_douyin.svg");
      }

      &-kuaishou {
        background-image: url("../../../assets/icon/icon_kuaishou.svg");
      }
    }
  }
}

.update-time {
  text-align: right;
}

.author-data {
  display: flex;

  .data-item {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    width: 150px;

    &__value {
      color: rgba(0, 0, 0, .85);
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.ant-list-item {
  padding: 0;
}

.sticky[fixed] {
  padding-top: 90px;
}

.sticky-fixed {
  background: #fff;
}
</style>
