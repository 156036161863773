<!--表单-->
<template>
  <div class="page">
    <a-row class="mb-24" type="flex">
      <a-col flex="1 0 0">
        <a-form-model ref="searchForm" :model="searchForm" layout="inline">
          <a-form-model-item label="直播时间" prop="dateRange">
            <TypeDateRangePicker :terraceType="terraceType" v-model="searchForm.dateRange" @change="handleDateRangeChange" />
          </a-form-model-item>
          <a-form-model-item label="有效直播时长(min)" prop="statisticsLiveTime">
            <a-input v-model="searchForm.statisticsLiveTime" allow-clear @pressEnter="handleLiveTimeEnter" />
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col>
        <a-button type="primary" :loading="exportLoading" @click="handleExport"> 导出 </a-button>
      </a-col>
    </a-row>

    <a-row class="mb-16" type="flex">
      <a-col flex="1 0 0">
        <div class="live-statistics-data">
          <div class="data-item">
            <div class="data-item__label">直播场次</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.liveCount >= 10000">
                  {{ statisticsData.liveCount }}
                </template>
                {{ statisticsData.liveCount | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.liveCount) >= 10000">
                  {{ compareData.liveCount }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.liveCount && compareData.liveCount !== null">
                  (<template v-if="compareData.liveCount > 0">+</template>{{ compareData.liveCount | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">直播时长</div>

            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.liveTime >= 10000">
                  {{ statisticsData.liveTime }}
                </template>
                {{ statisticsData.liveTime | humanReadable }}min
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">场均时长</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgLiveTime>=10000">
                  {{ statisticsData.avgLiveTime }}
                </template>
                {{ statisticsData.avgLiveTime | humanReadable }}min
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">场均观看人次</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgWatchUserCount>=10000">
                  {{ statisticsData.avgWatchUserCount }}
                </template>
                {{ statisticsData.avgWatchUserCount | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.avgWatchUserCount)>=10000">
                  {{ compareData.avgWatchUserCount }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.avgWatchUserCount && compareData.avgWatchUserCount !== null">
                  (<template v-if="compareData.avgWatchUserCount > 0">+</template
                  >{{ compareData.avgWatchUserCount | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">场均在线峰值</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgMaxOnlineUse>=10000">
                  {{ statisticsData.avgMaxOnlineUse }}
                </template>
                {{ statisticsData.avgMaxOnlineUser | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.avgMaxOnlineUser)>=10000">
                  {{ compareData.avgMaxOnlineUser }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.avgMaxOnlineUser && compareData.avgMaxOnlineUser !== null">
                  (<template v-if="compareData.avgMaxOnlineUser > 0">+</template
                  >{{ compareData.avgMaxOnlineUser | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">场均点赞</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgLikeCount>=10000">
                  {{ statisticsData.avgLikeCount }}
                </template>
                {{ statisticsData.avgLikeCount | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.avgLikeCount)>=10000">
                  {{ compareData.avgLikeCount }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.avgLikeCount && compareData.avgLikeCount !== null">
                  (<template v-if="compareData.avgLikeCount > 0">+</template
                  >{{ compareData.avgLikeCount | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <LiveListTable
      :loading="loading"
      :data="liveList"
      :pagination="pagination"
      :platform="platform"
      :principalIds="principalIds"
      :p9OauthStatus="p9OauthStatus"
      @table-change="handleTableChange"
      @pagination-change="handlePaginationChange"
      @operate="handleOperate"
    />
  </div>
</template>

<script>
import TypeDateRangePicker from '@/components/TypeDateRangePicker';
import LiveListTable from './components/LiveListTable';
import _http from '@/api/base/baseService';
import liveApi from '@/api/live';

// const defaultQuery = {
//   orderBys: "publishTime|desc",
//   dayOffset: undefined,
//   dateRanges: {
//     dateRange: {
//       startDate: undefined,
//       endDate: undefined
//     }
//   },
//   param: {
//     authorId: undefined
//   },
// };

const sortField = {
  publishTime: 1,
  statisticsLiveTime: 2,
  statisticsTotalWatchUserTime: 3,
  statisticsMaxOnlineUser: 4,
  statisticsTotalLikeCount: 5,
  statisticsTotalTicketCount: 6,
};

export default {
  components: {
    TypeDateRangePicker,
    LiveListTable,
  },
  props: {
    platform: {
      type: Number,
      required: true,
    },
    authorId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    principalIds: {
      type: String,
    },
    p9OauthStatus: [Number, String],
  },
  data() {
    return {
      terraceType:'live',
      searchForm: {
        dateRange: [
          30,
          [
            this.$moment().subtract(29, 'days').format('YYYY-MM-DD'),
            this.$moment().format('YYYY-MM-DD'),
          ]
        ],
        statisticsLiveTime: 0,
      },
      statisticsData: {},
      compareData: {},
      sorter: {
        order: 'descend',
        field: 'publishTime',
      },
      loading: false,
      liveList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      exportLoading: false,
    };
  },
  mounted() {
    this.$on('init', this.init);
  },
  methods: {
    init() {
      this.fetchLiveStatistics();
      this.fetchLiveStatisticsList();
    },
    handleDateRangeChange(val) {
      if (val[1].length > 0) {
        this.init();
      }
    },
    handleLiveTimeEnter() {
      this.init();
    },
    async fetchLiveStatistics() {
      try {
        const { dateRange, statisticsLiveTime } = this.searchForm;
        const params = {
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
          statisticsLiveTime,
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
          this.$set(params,'monthOffset',dateRange[2])
        }
        const { code, data, message, errmsg } = await liveApi.fetchLiveStatistics(params);

        if (code === 200) {
          this.statisticsData = data.statisticData || {};
          this.compareData = data.compareData || {};
        } else {
          this.$message.error(message || errmsg);
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleTableChange(pagination, filters, sorter, { currentDataSource }) {
      const { order, field } = sorter;

      this.sorter = { order, field };
      this.pagination.page = 1;
      this.fetchLiveStatisticsList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchLiveStatisticsList();
    },
    async fetchLiveStatisticsList() {
      this.loading = true;
      try {
        const { dateRange, statisticsLiveTime } = this.searchForm;
        const { order, field } = this.sorter;
        const { page, size } = this.pagination;
        const params = {
          principalId: this.principalIds,
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
          statisticsLiveTime,
          sort: order === 'ascend' ? 'ASC' : 'DESC',
          sortField: sortField[field],
          page,
          size,
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
        }
        const { code, data, message, errmsg } = await liveApi.fetchLiveStatisticsList(params);

        this.loading = false;

        if (code === 200) {
          this.liveList = data.list;
          this.pagination.total = data.total;
        } else {
          this.$message.error(message || errmsg);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true;
      try {
        const { dateRange, statisticsLiveTime } = this.searchForm;
        const { order, field } = this.sorter;
        const params = {
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
          statisticsLiveTime,
          sort: order === 'ascend' ? 'ASC' : 'DESC',
          sortField: sortField[field],
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
        }
        await liveApi.exportLiveStatisticsList(params);
        this.exportLoading = false;
      } catch (e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    handleOperate(type, record) {
      if (type === 'shareUrl') {
        this.$set(record, '__LOADING__', true);
        // 获取分享链接
        let params = {
          room_id: record.roomId,
        };
        _http.getUrl(params).then((res) => {
          if (res.code == 200) {
            let copyUrl = res.data.page_url || '';
            let aux = document.createElement('input');
            aux.setAttribute('value', copyUrl);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand('copy');
            console.log(document.execCommand('copy'));
            if (document.execCommand('copy')) {
              this.$message.success('链接已复制成功');
            } else {
              this.$message.error('该浏览器不支持自动复制');
            }
            document.body.removeChild(aux);
          } else {
            this.$message.error(res.message);
          }
          this.$set(record, '__LOADING__', false);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .live-statistics-data {
    display: flex;

    .data-item {
      display: flex;
      flex-flow: column-reverse;
      margin: 0 16px 0 0;
      min-width: 60px;

      &__label {
        margin: 4px 0 0;
        color: rgba(#000, 0.45);
        font-size: 14px;
        text-align: center;
      }

      &__value {
        color: rgba(#000, 0.85);
        font-size: 24px;
        font-weight: bold;
        text-align: center;

        span {
          font-size: 24px;
          &.data-item__spanTwo {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.a-tables-scroll /deep/ .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ul::-webkit-scrollbar {
    // 整个滚动条.
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    // 滚动条上的滚动滑块
    border-radius: 8px;
    background-color: #7f7f7f;
  }

  &::-webkit-scrollbar-corner {
    // 当同时有垂直滚动条和水平滚动条时交汇的部分
    background_color: #242832;
  }

  &::-webkit-scrollbar-track {
    // 滚动条轨道
    border-radius: 0;
    background_color: #242832;
  }
}
</style>
