<template>
  <div class="aweme-list-table" ref="awemeListTable">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => record.awemeId"
      :pagination="false"
      :scroll="scroll"
      @change="handleTableChange"
    >
      <div class="aweme-info" slot="awemeInfo" slot-scope="text, record">
        <div class="aweme-info__cover">
          <BaseCover :src="record.awemeCover" :width="104" :height="138" />
        </div>
        <div class="aweme-info__content">
          <div class="aweme-title">{{ record.awemeTitle || '-' }}</div>
          <div class="aweme-time">发布时间：{{ record.publishTime || '-' }}</div>
        </div>
      </div>
      <div slot="moduleInfo" slot-scope="text, record">
        <span v-if="record.moduleId"> {{ record.moduleName }}({{ record.moduleId }}) </span>
        <span v-else>否</span>
      </div>
      <div slot="playVolume" slot-scope="text, record">
        <a-tooltip>
          <template slot="title" v-if="record.playVolume >= 10000">
            {{ record.playVolume }}
          </template>
          {{ record.playVolume | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="playTotalCountAvgTime" slot-scope="text, record">
        <a-tooltip>
          <template slot="title" v-if="record.playTotalCountAvgTime>=10000">
            {{ record.playTotalCountAvgTime }}
          </template>
          {{ formatUtil.humanReadable(record.playTotalCountAvgTime) }}s
        </a-tooltip>
      </div>
      <div slot="diggCount" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.diggCount >=10000">
            {{ record.diggCount }}
          </template>
          {{ record.diggCount | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="commentCount" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.commentCount >=10000">
            {{ record.commentCount }}
          </template>
          {{ record.commentCount | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="shareCount" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.shareCount >=10000">
            {{ record.shareCount }}
          </template>
          {{ record.shareCount | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="fansTotalAdd" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.fansTotalAdd >=10000">
            {{ record.fansTotalAdd }}
          </template>
          {{ record.fansTotalAdd | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="awemeShareShortLinkUrl" slot-scope="text, record">
        <a v-if="record.awemeShareShortLinkUrl" :href="record.awemeShareShortLinkUrl" target="_blank">
          {{ record.awemeShareShortLinkUrl }}
        </a>
        <a v-else-if="record.awemeId != spinningId" @click="getShareUrl(record)">点击获取</a>
        <a-spin
          style="margin-left: 20px"
          v-show="record.awemeId == spinningId"
          tip="获取中"
          :spinning="spinning"
        ></a-spin>
      </div>
      <div slot="operate" slot-scope="text, record">
        <a @click="handleAweme(record)">播放视频</a>
        <div v-if="record.favoriteStatus === 1">已收藏</div>
        <a v-if="record.favoriteStatus !== 1" @click="handleOperate('collectAweme', record)"> 收藏视频 </a>
        <router-link
          :to="{
            name: record.platform === 1 ? 'awemeAnalysis' : 'awemeAnalysisKS',
            params: { awemeId: record.awemeId },
          }"
          target="_blank"
        >
          视频分析
        </router-link>
      </div>
    </a-table>
    <BasePagination
      ref="basePagination"
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import BaseCover from '@/components/BaseCover';
import resizeMixin from '@/mixins/dataTableMixin';
import formatUtil from '@/utils/FormatUtil';
import awemeApi from '@/api/aweme';

const columns = [
  {
    title: '视频(发布时间排序)',
    dataIndex: 'publishTime',
    key: 'awemeInfo',
    width: 400,
    sorter: true,
    scopedSlots: { customRender: 'awemeInfo' },
  },
  {
    title: '使用模板',
    key: 'moduleInfo',
    width: 150,
    scopedSlots: { customRender: 'moduleInfo' },
  },
  {
    title: '播放量',
    dataIndex: 'playVolume',
    width: 120,
    align: 'right',
    sorter: true,
    scopedSlots: { customRender: 'playVolume' },
  },
  {
    title: '完播率(%)',
    dataIndex: 'playTotalCountCompletionRate',
    width: 120,
    align: 'right',
    sorter: true,
    customRender: (text, record, index) => {
      if (record.playTotalCountCompletionRate !== null) {
        return `${formatUtil.humanReadable(record.playTotalCountCompletionRate)}%`;
      } else {
        return '-';
      }
    },
  },
  {
    title: '均播时长(s)',
    dataIndex: 'playTotalCountAvgTime',
    width: 130,
    align: 'right',
    sorter: true,
    scopedSlots: { customRender: 'playTotalCountAvgTime' },
  },
  {
    title: '点赞量',
    dataIndex: 'diggCount',
    width: 120,
    align: 'right',
    sorter: true,
    slotScope:{customRender:'diggCount'}
  },
  {
    title: '评论量',
    dataIndex: 'commentCount',
    width: 120,
    align: 'right',
    sorter: true,
    slotScope:{customRender:'commentCount'}
  },
  {
    title: '转发量',
    dataIndex: 'shareCount',
    width: 120,
    align: 'right',
    sorter: true,
    slotScope:{customRender:'shareCount'}
  },
  {
    title: '新增粉丝量',
    dataIndex: 'fansTotalAdd',
    width: 130,
    align: 'right',
    sorter: true,
    slotScope:{customRender:'fansTotalAdd'}
  },
  {
    title: '分享短链接',
    key: 'awemeShareShortLinkUrl',
    width: 250,
    scopedSlots: { customRender: 'awemeShareShortLinkUrl' },
  },
  {
    title: '更新时间',
    dataIndex: 'mtime',
    width: 200,
  },
  {
    title: '操作',
    key: 'operate',
    width: 100,
    fixed: 'right',
    scopedSlots: { customRender: 'operate' },
  },
];

export default {
  name: 'AwemeListTable',
  components: {
    BaseCover,
  },
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0,
        };
      },
    },
  },
  data() {
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };

    return {
      formatUtil:formatUtil,
      spinningId: '',
      spinning: false,
      columns,
      scroll: {},
    };
  },
  mounted() {
    this.setTableScroll();
  },
  methods: {
    // 播放视频
    handleAweme(record){
      if(record.awemeId) {
        let url;
        if(record.platformType == 1)
          url = `https://www.iesdouyin.com/share/video/${ record.awemeId }/?region=CN&mid=6796480868782230792&u_code=354ddbk0h2ih&titleType=title&timestamp=1581666967&utm_campaign=client_share&app=aweme&utm_medium=ios&tt_from=copy&utm_source=copy`;
        else
          url = record.awemeUrl;
        window.open(url);
      }
    },
    // 获取分享链接
    async getShareUrl(record) {
      this.spinningId = record.awemeId;
      this.spinning = true;
      let params = {
        awemeId: record.awemeId,
        platform: record.platformType,
      };
      const res = await awemeApi.fetchShareUrl(params);
      if (res.code == 200) {
        this.spinning = false;
        this.$message.success('获取成功');
        console.log(this.data.indexOf(record),'99999')
        this.data[this.data.indexOf(record)].awemeShareShortLinkUrl = res.data.link
      } else {
        this.spinning = false;
        this.spinningId = ''
        this.$message.error('获取失败，请15分钟后再试。如重试3次仍未成功，请联系产品');
      }
    },
    handleTableChange(pagination, filters, sorter, { currentDataSource }) {
      this.$emit('table-change', pagination, filters, sorter, { currentDataSource });
    },
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size);
    },
    handleOperate(type, record) {
      this.$emit('operate', type, record);
    },
    setTableScroll() {
      const width = this.$refs.awemeListTable.offsetWidth;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);
      const scroll = {
        y: 600,
      };

      if (width < columnsWidth) {
        scroll.x = columnsWidth;
      }

      this.scroll = scroll;
    },
  },
};
</script>

<style lang="less" scoped>
.aweme-info {
  display: flex;

  &__cover {
    margin: 0 8px 0 0;
  }

  &__content {
    flex: 1;
  }

  .aweme-time {
    margin: 4px 0 0;
    color: rgba(#000, 0.45);
  }
}
</style>
