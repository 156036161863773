<template>
    <div>
        <div class="wrap">
            <div class="main-title">
                <div class="title">帐号诊断</div>
                <div class="time">统计周期：{{ detailTime[0] || '-' }} ~ {{ detailTime[1] || '-' }}（每天13点更新）</div>
            </div>
            <div class="content">
                <div class="item">
                    <div class="title">投稿数：</div>
                    <div class="txt">
                        你的投稿数为<span class="bold">{{ detailInfo.publish.user_value | changeNum }}</span>，{{ detailInfo.publish.user_rank ? '高于' : '低于' }}<span class="bold">{{ formatRate(detailInfo.publish.user_rank) || 0 }}%</span>的同类创作者
                    </div>
                </div>
                <div class="item">
                    <div class="title">视频播放量：</div>
                    <div class="txt">
                        你的作品播放量为<span class="bold">{{ detailInfo.play.user_value | changeNum }}</span>，{{ detailInfo.play.user_rank ? '高于' : '低于' }}<span class="bold">{{ formatRate(detailInfo.play.user_rank) || 0 }}%</span>的同类创作者
                    </div>
                </div>
                <div class="item">
                    <div class="title">视频完播率：</div>
                    <div class="txt">
                        你的作品完播率为<span class="bold">{{ formatRate(detailInfo.finish.user_value) }}%</span>，{{ formatRateType(detailInfo.finish.user_rank) ? '高于' : '低于' }}<span class="bold">{{ formatRate(detailInfo.finish.user_rank, 1) }}%</span>的同类创作者
                    </div>
                </div>
                <div class="item">
                    <div class="title">互动指数：</div>
                    <div class="txt">
                        你的互动指数为<span class="bold">{{ formatRate(detailInfo.interaction.user_value) }}%</span><span v-if="detailInfo.interaction.user_rank">，{{ formatRateType(detailInfo.interaction.user_rank) ? '高于' : '低于' }}<span class="bold">{{ formatRate(detailInfo.interaction.user_rank, 1) }}%</span>的同类创作者</span>
                    </div>
                </div>
                <div class="item">
                    <div class="title">粉丝净增量：</div>
                    <div class="txt">
                        你的粉丝净增量为<span class="bold">{{ detailInfo.fans.user_value | changeNum }}</span>，{{ detailInfo.fans.user_rank ? '高于' : '低于' }}<span class="bold">{{ formatRate(detailInfo.fans.user_rank) || 0 }}%</span>的同类创作者
                    </div>
                </div>
            </div>
        </div>

        <div class="wrap">
            <a-spin :spinning="fansOverviewLoading">
                <div class="main-title">
                    <div class="title">核心数据趋势</div>
                    <div class="right">
                        <div class="select">
                            <span>时间选择</span><span>｜</span>
                            <a-select
                                v-model='selectTime'
                                @change='changeTime'
                            >
                                <a-select-option v-for="item in timeTypeList" :key="item.key">
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="time" v-if="selectTime === 1">
                            统计周期：{{ fansOverviewTime[1] || '-' }}（每天13点更新）
                        </div>
                        <div class="time" v-else>
                            统计周期：{{ fansOverviewTime[0] || '-' }} ~ {{ fansOverviewTime[1] || '-' }}（每天13点更新）
                        </div>
                    </div>
                </div>
                
                <div class="tab-box">
                    <div :class="['tab', tabActive == 1 ? 'active' : '']" @click="onTab(1, '播放量')">
                        <div class="row">
                            <span class="title">播放量</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.view_play_cnt_last_period_inc) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.view_play_cnt_last_period_inc, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.view_play_cnt, '3') }}
                        </div>
                    </div>
                    <div :class="['tab', tabActive == 2 ? 'active' : '']" @click="onTab(2, '主页访问')">
                        <div class="row">
                            <span class="title">主页访问</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.interview_cnt_last_period_inc) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.interview_cnt_last_period_inc, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.interview_cnt, '3') }}
                        </div>
                    </div>
                    <div :class="['tab', tabActive == 3 ? 'active' : '']" @click="onTab(3, '作品点赞')">
                        <div class="row">
                            <span class="title">作品点赞</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.like_cnt_last_period_inc	) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.like_cnt_last_period_inc	, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.like_cnt, '3') }}
                        </div>
                    </div>
                    <div :class="['tab', tabActive == 4 ? 'active' : '']" @click="onTab(4, '作品分享')">
                        <div class="row">
                            <span class="title">作品分享</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.share_cnt_last_period_inc) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.share_cnt_last_period_inc, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.share_cnt, '3') }}
                        </div>
                    </div>
                    <div :class="['tab', tabActive == 5 ? 'active' : '']" @click="onTab(5, '作品评论')">
                        <div class="row">
                            <span class="title">作品评论</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.comment_cnt_last_period_inc) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.comment_cnt_last_period_inc, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.comment_cnt, '3') }}
                        </div>
                    </div>
                    <div :class="['tab', tabActive == 6 ? 'active' : '']" @click="onTab(6, '净增粉丝')">
                        <div class="row">
                            <span class="title">净增粉丝</span>
                            <span
                                :class="[
                                    'num',
                                    `num-${ formatNum(fansOverviewData.increase_fans_cnt_last_period_inc) }`
                                ]"
                            >
                                {{ formatNum(fansOverviewData.increase_fans_cnt_last_period_inc, '2') }}
                            </span>
                        </div>
                        <div class="data">
                            {{ formatNum(fansOverviewData.increase_fans_cnt, '3') }}
                        </div>
                    </div>
                </div>

                <div class="chart-box">
                    <div class="title">
                        <div class="txt">
                            {{ selectTime !== 30 ? '近7日' : '近30日' }}
                        </div>
                        <div v-if="tabActive == 6">
                            <a-select
                                v-model='selectFans'
                                @change='changeFans'
                            >
                                <a-select-option :key="1">净增粉丝</a-select-option>
                                <a-select-option :key="2">取关粉丝</a-select-option>
                                <a-select-option :key="3">全部粉丝</a-select-option>
                            </a-select>
                        </div>
                        <div v-else>{{ tabActiveText }}</div>
                    </div>
                    <div class="chart">
                        <BaseCharts
                            v-if="overviewData"
                            key="line"
                            :style="{width:'100%',height:'100%'}"
                            :option="overviewData"
                        />
                        <div class="no-data" v-else>
                            <a-empty key="" />
                        </div>
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
import BaseCharts from "@/components/BaseChart";
import dayjs from 'dayjs';

export default {
    components: {
        BaseCharts
    },
    props: {
        authorId: {
            type: String,
            required: true
        }
    },
    data() {
        let that = this;

        return {
            detailTime: ['', ''],
            detailInfo: {
                publish: {},
                play: {},
                finish: {},
                interaction: {},
                fans: {},
            },
            selectTime: 1,
            timeTypeList: [
                { label: '昨日', key: 1 },
                { label: '近7日', key: 7 },
                { label: '近30日', key: 30 }
            ],
            tabActive: 1,
            tabActiveText: '播放量',
            selectFans: 1,
            lineData: {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: 'line',
                        lineStyle: {
                            type: 'solid',
                            color: 'rgba(255, 255, 255, .1)',
                            shadowColor: 'transparent',
                            shadowBlur: 0
                        },
                    },
                    backgroundColor: 'none',
                    borderWidth: '0',
                    padding: 0,
                    formatter: function (params, ticket, callback) {
                        let _params = params[0]
                        let symbol = ''
                        let _rate = 0

                        try {
                            if(_params.data.rate.indexOf('+') == 0) {
                                symbol = '↑'
                                _rate = 1
                            } else if(_params.data.rate.indexOf('-') == 0) {
                                symbol = '↓'
                                _rate = -1
                            }
                        } catch {}

                        return `
                            <div style="padding: 10px; min-width: 136px; background: #fff;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);">
                                <div style="padding-bottom:10px; font-size: 12px; color: #999; line-height: 1;">
                                    ${_params.axisValue}
                                </div>
                                <div style="padding-bottom:10px; font-size: 12px; color: #000; line-height: 1;">
                                    ${ that.tabActiveText }&nbsp;${ that.filtrate.changeNum(_params.data.value, 3, false) }
                                </div>
                                <div style="font-size: 12px; color: #000; line-height: 1;">
                                    较昨日&nbsp;<span style='color:${ _rate ? _rate > 0 ? 'red' : 'green' : '#000' }'>${ symbol }${ _params.data.rate }</span>
                                </div>
                            </div>
                        `
                    }
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true
                },
                color: "#fe496d", // 自定义颜色
                xAxis: {
                    axisTick: {
                        show: false
                    },
                    // boundaryGap : false,
                    axisLine: {
                        lineStyle: {
                            color: '#ddd',
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        show: true,
                        textStyle: {
                            color: '#999'
                        },
                        interval: 'auto',
                    },
                },
                yAxis: {
                    type: "value",
                    // min: 'dataMin',
                    scale: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        formatter: (value, index) => {
                            return this.filtrate.changeNum(value, 3, false)
                        },
                        color: '#999'
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#ddd',
                        }
                    }
                },
                series: [{
                    type: "line",
                    barWidth: 30,
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 7
                }]
            },
            fansOverviewLoading: false,
            fansOverviewTime: ['', ''],
            fansOverviewData: {},
            overviewData: null
        }
    },
    created() {
        this.getData();
        this.fetchFansOverview();
    },
    methods: {
        formatNum(num, type) {
            if(type == '2') {
                return num ? num > 0 ? `+${this.filtrate.changeNum(num)}` : this.filtrate.changeNum(num) : '-'
            } else if(type == '3') {
                return (num ?? '') !== '' ? this.filtrate.changeNum(num) : '-'
            } else {
                return num ? num > 0 ? '1' : '2' : 0
            }
        },
        formatRateType(val) {
            return (val || 0) > 0.5
        },
        formatRate(val, type) {
            let _val = val || 0

            if(type) {
                _val = val > 0.5 ? val : 1 - val
            }

            return (_val * 100).toFixed(2)

        },
        getData() {
            this.$api.awemeAnalysis.accountDiagnosis({ author_id: this.authorId }).then((res) => {
                if(res.code == 200) {
                    this.detailInfo = res.data;
                    this.setDetailTime();
                } else {
                    console.error('获取帐号诊断数据失败')
                }
            });
        },
        setDetailTime() {
            this.detailTime = this.getStatisticalTime(7);
        },
        changeTime() {
            this.fetchFansOverview();
        },
        async fetchFansOverview() {
            this.fansOverviewLoading = true
            try {
                const params = {
                    author_id: this.authorId,
                    day_size: this.selectTime !== 30 ? this.selectTime : '' 
                }
                const { code, data, message } = await this.$api.awemeAnalysis.fansOverview(params)

                this.fansOverviewLoading = false

                if (code === 200) {
                    this.fansOverviewData = data;
                    this.setFansOverviewTime();
                    this.setOverviewData();
                } else {
                    this.$message.error(message);
                }
            } catch (e) {
                this.fansOverviewLoading = false;
                console.log(e);
            }
        },
        setFansOverviewTime() {
            this.fansOverviewTime = this.getStatisticalTime(this.selectTime);
        },
        onTab(index, type) {
            this.tabActive = index;
            this.tabActiveText = type;
            this.setOverviewData();
        },
        changeFans() {
            this.selectFans === 1 && (this.tabActiveText =  '净增粉丝');
            this.selectFans === 2 && (this.tabActiveText = '取关粉丝');
            this.selectFans === 3 && (this.tabActiveText = '全部粉丝');
            this.setOverviewData();
        },
        setOverviewData() {
            const dataKeys = [
                'view_play',
                'interview',
                'like',
                'share',
                'comment',
                ['net_increase_fans', 'unfollow', 'all_fans']
            ];
            let data = []; 
            let xAxisData = [];
            let yAxisData = [];

            if (this.tabActive !== 6) {
                data = this.fansOverviewData[dataKeys[this.tabActive - 1]] || [];
            } else {
                data = this.fansOverviewData[dataKeys[6 - 1][this.selectFans - 1]] || [];
            }

            if (data.length > 0) {
                data.map(item => {
                    xAxisData.push(item.date)
                    yAxisData.push({
                        value: item.count,
                        rate: item.last_day_incr_rate || 0
                    })
                })

                const obj = {
                    xAxis: {
                        data: xAxisData,
                    },
                    series: [
                        { data: yAxisData }
                    ]
                }

                this.overviewData = _.merge(_.cloneDeep(this.lineData), obj)
            } else {
                this.overviewData = null
            }
        },
        getStatisticalTime(type) {
            const isBefore13 = dayjs().isBefore(dayjs().format('YYYY-MM-DD 13:00:00'));
            let start;
            let end;

            if (type === 1 || type === 7) {
                start = isBefore13 ?
                    dayjs().subtract(8, 'day').format('YYYY-MM-DD') :
                    dayjs().subtract(7, 'day').format('YYYY-MM-DD');
                end = isBefore13 ?
                    dayjs().subtract(2, 'day').format('YYYY-MM-DD') :
                    dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            } else if (type === 30) {
                start = isBefore13 ?
                    dayjs().subtract(31, 'day').format('YYYY-MM-DD') :
                    dayjs().subtract(30, 'day').format('YYYY-MM-DD');
                end = isBefore13 ?
                    dayjs().subtract(2, 'day').format('YYYY-MM-DD') :
                    dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            } else {
                start = ''
                end = ''
            }

            return [start, end];
        }
    }
}
</script>

<style scoped lang="less">
.wrap {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    .main-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .title {
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }
        .select {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bold;
        }
        /deep/ .ant-select {
            width: 73px;
        }
    }
    .content {
        .item {
            margin-bottom: 15px;
            .title {
                font-size: 16px;
                font-weight: bold;
                color: #000;
            }
            .txt {
                margin-left: 30px;
                line-height: 2;
                .bold {
                    font-weight: bold;
                }
            }
        }
    }
    .tab-box {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        .tab {
            padding: 20px;
            width: 16%;
            background-color: rgba(149, 184, 209, 0.1);
            border-radius: 10px;
            cursor: pointer;
            transition: all .3s;
            &.active {
                background-color: #fe496d;
                .title {
                    color: rgba(255, 255, 255, .7);
                }
                .num, .data {
                    color: #fff;
                }
            }
        }
        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .title {
            font-size: 12px;
            color: #999;
        }
        .num {
            font-weight: bold;
            color: #999;
            &-1 {
                color: #fe496d;
            }
            &-2 {
                color: #1ae31a ;
            }
        }
        .data {
            margin-top: 5px;
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }
    }
    .chart-box {
        padding: 20px;
        background-color: rgba(149, 184, 209, 0.1);
        border-radius: 10px;
        .title {
            display: flex;
            align-items: center;
            color: #999;
            height: 32px;
            .txt {
                font-size: 14px;
                color: #999;
            }
            /deep/ .ant-select-selection__rendered {
                font-size: 14px;
                color: #999;
                line-height: 32px;
            }
        }
        .chart {
            height: 400px;
        }

        .no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

/deep/ .ant-select-selection {
    border: none;
    background-color: unset;
}
/deep/ .ant-select-selection__rendered {
    margin-left: 0;
}
</style>