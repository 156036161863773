import { render, staticRenderFns } from "./FansLine.vue?vue&type=template&id=24babade&scoped=true&"
import script from "./FansLine.vue?vue&type=script&lang=js&"
export * from "./FansLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24babade",
  null
  
)

export default component.exports