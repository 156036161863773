<template>
  <div class="live-list-table" ref="liveListTable">
    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => record.roomId"
        :pagination="false"
        :scroll="scroll"
        @change="handleTableChange"
    >
      <div class="live-info" slot="liveInfo" slot-scope="text, record">
        <div class="live-info__cover">
          <BaseCover
              :src="record.cover"
              :width="104"
              :height="138"
          />
        </div>
        <div class="live-info__content">
          <div class="live-title">{{ record.title }}</div>
          <div class="live-time">开播时间：{{ record.publishTime }}</div>
        </div>
      </div>
      <div slot="statisticsLiveTime" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.statisticsLiveTime>=10000">
            {{ record.statisticsLiveTime }}
          </template>
          {{ record.statisticsLiveTime | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="statisticsTotalWatchUserTime" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.statisticsTotalWatchUserTime>=10000">
            {{ record.statisticsTotalWatchUserTime }}
          </template>
          {{ record.statisticsTotalWatchUserTime | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="statisticsMaxOnlineUser" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.statisticsMaxOnlineUser>=10000">
            {{ record.statisticsMaxOnlineUser }}
          </template>
          {{ record.statisticsMaxOnlineUser | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="statisticsTotalLikeCount" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.statisticsTotalLikeCount>=10000">
            {{ record.statisticsTotalLikeCount }}
          </template>
          {{ record.statisticsTotalLikeCount | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="statisticsTotalTicketCount" slot-scope="text,record">
        <a-tooltip>
          <template slot="title" v-if="record.statisticsTotalTicketCount>=10000">
            {{ record.statisticsTotalTicketCount }}
          </template>
          {{ record.statisticsTotalTicketCount | humanReadable }}
        </a-tooltip>
      </div>
      <div slot="operate" slot-scope="text, record">
        <!--  <router-link
            :to="{
              name: 'liveAnalysis',
              params: { roomId: record.roomId },
              query: { author_id: record.authorId }
            }"
            target="_blank"
          >
            直播分析
          </router-link>
  -->
        <template v-if="record.playUrl">
          <a @click="handleOperate('shareUrl', record)">获取分享链接</a>
          <a-divider type="vertical" v-if="record.isCooperativePrincipal" />
        </template>
        
        <template v-if="platform == 1 && record.isCooperativePrincipal">
          <a @click="jumpLiveDetailDY(record)">{{ record.isSubmittedP9LiveStatus ? '直播分析' : (record.txt || '发起分析') }}</a>
        </template>
        <template v-if="platform == 2 && record.isCooperativePrincipal">
          <a @click="jumpLiveDetailKS(record)">直播分析</a>
        </template>
      </div>
    </a-table>

    <BasePagination
        ref="basePagination"
        :currentPage="pagination.page"
        :pageSize="pagination.size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import BaseCover from '@/components/BaseCover'
import resizeMixin from '@/mixins/dataTableMixin'
import formatUtil from '@/utils/FormatUtil'
import liveApi from '@/api/live'

const columns = [
  {
    title: "直播封面/标题",
    dataIndex: "publishTime",
    key: "liveInfo",
    width: 400,
    sorter: true,
    scopedSlots: {customRender: "liveInfo"}
  },
  {
    title: "直播时长(min)",
    dataIndex: "statisticsLiveTime",
    key: "statisticsLiveTime",
    width: 130,
    align: "right",
    sorter: true,
    scopedSlots:{customRender: 'statisticsLiveTime'}
  },
  {
    title: "观看人次",
    dataIndex: "statisticsTotalWatchUserTime",
    width: 120,
    align: "right",
    sorter: true,
    scopedSlots:{customRender: 'statisticsTotalWatchUserTime'}
  },
  {
    title: "在线人数峰值",
    dataIndex: "statisticsMaxOnlineUser",
    width: 130,
    align: "right",
    sorter: true,
    scopedSlots:{customRender: 'statisticsMaxOnlineUser'}
  },
  {
    title: "点赞量",
    dataIndex: "statisticsTotalLikeCount",
    width: 120,
    align: "right",
    sorter: true,
    scopedSlots:{customRender: 'statisticsTotalLikeCount'}
  },
  {
    title: `送礼收入(音浪)`,
    dataIndex: "statisticsTotalTicketCount",
    width: 140,
    align: "right",
    sorter: true,
    scopedSlots:{customRender: 'statisticsTotalTicketCount'}
  },
  {
    title: "更新时间",
    dataIndex: "mtime",
    width: 180
  },
  {
    title: "操作",
    key: "operate",
    width: 200,
    fixed: "right",
    scopedSlots: {customRender: "operate"}
  }
];

export default {
  name: "LiveListTable",
  components: {
    BaseCover
  },
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        };
      }
    },
    platform: [Number],
    principalIds: {
      type: String
    },
    p9OauthStatus: [Number, String],
  },
  data() {
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };

    return {
      columns,
      scroll: {},
      taskProcess: false,
    }
  },
  mounted() {
    this.setTableScroll();
  },
  methods: {
    handleTableChange(pagination, filters, sorter, {currentDataSource}) {
      this.$emit("table-change", pagination, filters, sorter, {currentDataSource});
    },
    handlePaginationChange(page, size) {
      this.$emit("pagination-change", page, size);
    },
    handleOperate(type, record) {
      this.$emit("operate", type, record);
    },
    setTableScroll() {
      const width = this.$refs.liveListTable.offsetWidth;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);
      const scroll = {
        y: 600
      };

      if(width < columnsWidth) {
        scroll.x = columnsWidth;
      }

      this.scroll = scroll
    },
    async jumpLiveDetailDY(record) {
      if(this.p9OauthStatus == 1) {
        let routeUrl = this.$router.resolve({
          path: `/mediaCenter/liveReviewAssistant/${record.roomId}`,
          query: {
            author_id: record.authorId, 
            platform: this.platform, 
            principalId: this.principalIds
          }
        })
        window.open(routeUrl.href, '_blank')

        if(record.isSubmittedP9LiveStatus || record.taskProcess) {
          console.log('已经提交过了')
        } else {
          this.$set(record, 'txt', '分析数据生成中')
          const params = {
            platform: this.platform == 1 ? 'douyin' : 'kuaishou',
            paramsIds: [{
              authorId: record.authorId,
              paramsIds: [record.roomId]
            }],
            startTime: record.publishTime,
            endTime: record.finishTime,
          }
          const { code, data, message } = await liveApi.submitP9Task(params)

          if (code === 200) {
            this.$set(record, 'taskProcess', data.submitResult)
            // this.taskProcess = data
          } else {
            this.$message.error(message)
          }
        }
      } else {
        this.$message.error('请先完成P9（线索数据）授权')
        return
      }
    },
    jumpLiveDetailKS(record) {
      let routeUrl = this.$router.resolve({
        path: `/mediaCenter/liveReviewAssistant/${record.roomId}`,
        query: {
          author_id: record.authorId, 
          platform: this.platform, 
          principalId: this.principalIds
        }
      })
      window.open(routeUrl.href, '_blank')
    },
  }
};
</script>

<style lang="less" scoped>
.live-info {
  display: flex;

  &__cover {
    margin: 0 8px 0 0;
  }

  &__content {
    flex: 1;
  }

  .live-time {
    margin: 4px 0 0;
    color: rgba(#000, .45);
  }
}
</style>
