<template>
  <a-modal
    :visible="value"
    title="新建播主分组"
    :confirmLoading="confirmLoading"
    :mask-closable="false"
    @cancel="handleCancel"
    @ok="handleConfirm"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 90px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 90px)'}"
    >
      <a-form-model-item label="分组类型" prop="groupType">
        <a-select
          v-model="form.groupType"
          style="width: 300px;"
        >
          <a-select-option
            v-for="item in typeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin: 0 0 0 8px;">
          {{ form.groupType === 1 ? '所有人可见' : '仅自己可见' }}
        </span>
      </a-form-model-item>
      <a-form-model-item label="分组名称" prop="groupName">
        <a-input
          v-model="form.groupName"
          allow-clear
          placeholder="请输入分组名称"
          style="width: 300px;"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'AddAuthorGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          groupType: 1,
          groupName: ''
        }
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        groupType: [
          { required: true, message: '分组类型不能为空', trigger: 'change' }
        ],
        groupName: [
          { required: true, message: '分组名称不能为空', trigger: 'blur' }
        ]
      },
      typeList: [
        { label: '公共分组', value: 1 },
        { label: '私有分组', value: 2 }
      ]
    }
  },
  methods: {
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}
</style>
