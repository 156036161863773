import axios from "axios";
import { auth } from "@/utils";
import _ from 'lodash'

export default function (url, params = {}, fileName = "资料") {
  const token = auth.getToken();
  return axios
    .get(url, {
      baseURL: process.env.VUE_APP_BASE_API,
      responseType: "blob",
      params,
      headers: {
        Authorization: "Bearer " + token,
      }
    })
    .then((res) => {
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel",
      });
      let name = _.get(res, 'headers.content-disposition', '')
      if(name) {
        name = name.split(';')[1]
        if(name) {
          name = window.decodeURIComponent(name)
          name = name.split('=')[1]
        } else {
          name = fileName
        }
      } else {
        name = fileName
      }
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = objectUrl;
      a.download = `${name}.xlsx`;
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      window.URL.revokeObjectURL(blob);
      return res
    });
}