<template>
  <a-card>
    <a-row style="padding-top: 20px">
      <a-col :span="11">
        <div class="echart-box">
          <BaseCharts
              key="pie"
              :option="sexData"
              :style="{height:'400px'}"
          />
        </div>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11">
        <div class="echart-box">
          <BaseCharts
              class="bar-box"
              ref="BaseCharts"
              key="bar"
              :style="{width:'95%',height:'400px'}"
              :option="ageData"
          />
        </div>
      </a-col>
    </a-row>
    <a-row style="padding-top: 20px">
      <a-col :span="23">
        <div class="echart-box">
          <BaseCharts
              key="map"
              :option="provinceData"
              :style="{height:'400px'}"
          />
          <div class="table-box">
            <a-table
                :rowKey="(record, index) => index"
                :columns="provinceColumns"
                :data-source="provinceList"
                :pagination="false"
                :scroll="{ y: 400 }"
            >

            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row style="padding-top: 20px">
      <a-col :span="11">
        <div class="echart-box">
          <BaseCharts
              key="pie"
              :option="equipmentData"
              :style="{height:'400px'}"
          />
        </div>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11" class="table">
          <div class="main-title">粉丝兴趣分布</div>
          <a-table
              :rowKey="(record, index) => index"
              :columns="interestColumns"
              :data-source="interestList"
              :pagination="false"
              :scroll="{ y: 300 }"
          >
          </a-table>
      </a-col>
    </a-row>
    <a-row style="padding-top: 20px">
      <a-col :span="11" class="table">
          <div class="main-title">粉丝关注热词</div>
          <a-table
              :rowKey="(record, index) => index"
              :columns="hotWordColumns"
              :data-source="hotWordList"
              :pagination="false"
              :scroll="{ y: 300 }"
          >
          </a-table>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11" class="table">
          <div class="main-title">新增粉丝关注热词</div>
          <a-table
              :rowKey="(record, index) => index"
              :columns="newWordColumns"
              :data-source="newWordList"
              :pagination="false"
              :scroll="{ y: 300 }"
          >
          </a-table>
      </a-col>
    </a-row>
    <a-row style="padding-top: 20px">
      <a-col :span="11">
        <div class="echart-box">
          <BaseCharts
              key="pie"
              :option="activeData"
              :style="{height:'400px'}"
          />
        </div>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11"></a-col>
    </a-row>
  </a-card>
</template>

<script>
import BaseCharts from "@/components/BaseChart";
//引入中国地图依赖
import "echarts/map/js/china";

const pieData = {
  legend: {
    top: "bottom",
    icon: "circle",
    itemGap: 20,
    itemWidth: 10
  },
  grid: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  title: {
    top: 0,
    left: "left"
  },
  tooltip: {
    trigger: "item",
  },
  color: ["#1dcfe9", "#5dbaf0", '#67c2f2', '#90c6f5', '#b9d3f7', '#d7e2fb', '#e6eafb', '#b8c0ff', '#c8b6ff', '#e7c6ff', '#ffd6ff'], // 自定义饼图颜色
  series: [{
    type: "pie",
    radius: ["40%", "60%"],
    itemStyle: {
      borderColor: "#fff",
      borderWidth: 1
    },
    label: {
      alignTo: "item",
      // formatter: "{d}% \n  {name|{b}}",
      formatter: function (params, ticket, callback) {
        return (params.percent).toFixed(0) + '%\n' + params.name
      },
      minMargin: 2,
      edgeDistance: 10,
      lineHeight: 18,
      fontSize: 14,
      fontWeight: "bolder",
      color: "#888",
      rich: {
        icon: {
          fontSize: 12,
        },
        name: {
          fontSize: 12,
          color: "#888"
        },
      }
    },
    labelLine: {
      length: 30,
      length2: 8,
    },
  }]
}
const barData = {
  title: {
    text: "年龄分布",
    top: 0,
    left: "left"
  },
  color: ["#1dcfe9"], // 自定义颜色
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: 'line',
      lineStyle: {
        type: 'solid',
        color: 'rgba(255, 255, 255, .1)',
        shadowColor: 'transparent',
        shadowBlur: 0
      },
    },
    backgroundColor: 'none',
    borderWidth: '0',
    padding: 0,
    formatter: function (params, ticket, callback) {
      let _params = params[0]

      return `
        <div style="padding: 15px 10px; min-width: 136px; background: #fff;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);">
            <div style="font-size: 12px; color: #999; line-height: 1;">${ _params.axisValue }<span style="color: #1dcfe9; font-weight: bold; padding-left: 7px;">${ _params.data }%</span></div>
        </div>
      `
    }
  },
  xAxis: [
    {
      type: "category",
      axisPointer: {
        type: "shadow"
      },
      axisLabel: {
        //x轴文字的配置
        show: true,
        interval: 0,//使x轴文字显示全
      },
      axisTick: {
        alignWithLabel: true,// x轴是否与柱子对齐
      }
    }
  ],
  yAxis: [
    {
      type: "value",
      name: "",
      // max: 100,
      // min: 0,
      axisLabel: {
        show: true,  
        interval: 'auto',  
        formatter: "{value}%"
      },
      axisLine: {show: false}, // y轴不显示线
      axisTick: {
        show: false, // 不显示y轴刻度
      },
    },
  ],
  series: [{
    type: "bar",
    barWidth: 30
  }]
}
const mapData = {
  title: {
    text: "地域分布",
  },
  tooltip: {
    trigger: "item",
    axisPointer: {
      type: 'line',
      lineStyle: {
          type: 'solid',
          color: 'rgba(255, 255, 255, .1)',
          shadowColor: 'transparent',
          shadowBlur: 0
      },
    },
    backgroundColor: 'none',
    borderWidth: '0',
    padding: 0,
    formatter: function (params, ticket, callback) {
      if(!isNaN(params.value) ){
        return `
          <div style="padding: 15px 10px; min-width: 136px; background: #fff;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);">
              <div style="font-size: 12px; color: #999; line-height: 1;">${ params.name }<span style="color: #1dcfe9; font-weight: bold; padding-left: 7px;">${ params.value }</span></div>
          </div>
        `
      }
    }
  },
  geo: {
    map: "china", //地图类型
    zoom: 1.2,//视角缩放比例
    label: {
      show: false
    },
    emphasis: { //高亮
      label: { //地图的标签名字
        show: true
      },
      itemStyle: { //区域样式 hover样式
        areaColor: "#fed92c",
        color: "#333"
      }
    }
  },
  series: [{
    name: "地图",
    type: "map", //地图
    mapType: "china",
    geoIndex: 0, //映射visualMap 颜色配置
    roam: true,
    label: {
      normal: {
        formatter: "{a}",
        show: true  //省份名称
      },
      emphasis: {
        show: false
      }
    },
    map: "china",
  }]
}

export default {
  name: "fans_portrait",
  components: {BaseCharts},
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: [
        "Racing car sprays burning fuel into crowd.",
        "Japanese princess to wed commoner.",
        "Australian walks 100km after outback crash.",
        "Man charged over missing wedding girl.",
        "Los Angeles battles huge wildfires.",
        "Man charged over missing wedding girl.",
        "Los Angeles battles huge wildfires.",
        "Man charged over missing wedding girl.",
        "Los Angeles battles huge wildfires.",
        "Man charged over missing wedding girl.",
        "Los Angeles battles huge wildfires.",
      ],
      sexData: {},
      ageData: {},
      provinceData: {},
      provinceColumns: [
        {
          title: "地区",
          dataIndex: "name",
          className: "replacecolor"
        },
        {
          title: "占比",
          // dataIndex: "value",
          className: "replacecolor",
          customRender(text, row, index) {
            return row.rate ? `${row.rate}%` : '-'
          }
        },
      ],
      provinceList: [],
      equipmentData: {},
      interestColumns: [
        {
          title: "兴趣",
          dataIndex: "dimension",
        },
        {
          title: "占比",
          // dataIndex: "value",
          customRender(text, row, index) {
            return row.rate ? `${row.rate}%` : '-'
          }
        },
      ],
      interestList: [],
      hotWordColumns: [
        {
          title: "兴趣",
          dataIndex: "dimension",
        },
        {
          title: "占比",
          // dataIndex: "value",
          customRender(text, row, index) {
            return row.rate ? `${row.rate}%` : '-'
          }
        },
      ],
      hotWordList: [],
      newWordColumns: [
        {
          title: "兴趣",
          dataIndex: "dimension",
        },
        {
          title: "占比",
          // dataIndex: "value",
          customRender(text, row, index) {
            return row.rate ? `${row.rate}%` : '-'
          }
        },
      ],
      newWordList: [],
      activeData: {},
    }
  },
  created() {
    this.handlePieOptions([
      {value: 0, name: '男性'},
      {value: 0, name: '女性'},
    ]);
    this.handleBarOptions([]);
    this.handleMapOptions();
    this.handleEquipmentOptions([{value: 0, name: ""}])
    this.handleActiveOptions([{value: 0, name: ""}])

    this.fansPortrait()
  },
  methods: {
    handlePieOptions(data) {
      let obj = {
        title: {
          text: "性别分布",
        },
        series: [
          { data: data }
        ]
      }
      this.sexData = _.merge(_.cloneDeep(pieData), obj)
    },  // 处理饼图数据
    handleBarOptions(data) {
      let xAxis = []
      let yAxis = []
      if(data) {
        let total = 0
        data.map(item => {
          total = total + Number(item.value)
        })
        
        data.map(item => {
          xAxis.push(item.dimension)
          yAxis.push((item.value / total * 100).toFixed(1))
        })
      }
      let obj = {
        xAxis: [
          {
            data: xAxis
          }
        ],
        series: [
          { data: yAxis }
        ]
      }
      this.ageData = _.merge(_.cloneDeep(barData), obj)
    },  // 处理柱状图数据
    handleMapOptions(data) {
      let obj = {
        series: [{
          data: data
        }]
      }
      this.provinceData = _.merge(_.cloneDeep(mapData), obj)
    },  // 处理地图数据

    //设备
    handleEquipmentOptions(data) {
      let obj = {
        title: {
          text: "设备分布",
        },
        series: [
          { data: data }
        ]
      }
      this.equipmentData = _.merge(_.cloneDeep(pieData), obj)
    },
    //活跃分布
    handleActiveOptions(data) {
      let obj = {
        title: {
          text: "活跃分布",
        },
        series: [
          { data: data }
        ]
      }
      this.activeData = _.merge(_.cloneDeep(pieData), obj)
    },
    fansPortrait() {
        this.$api.awemeAnalysis.fansPortrait({ author_id: this.authorId }).then((res) => {
            if(res.code == 200) {
              let _data = res.data
              
              this.interestList = _data.fans_interest_data || []
              let interestTotal = 0
              this.interestList.map(item => {
                interestTotal = interestTotal + Number(item.value)
              })
              this.interestList.map(item => {
                item.rate = (Number(item.value) / interestTotal * 100).toFixed(2)
              })

              this.hotWordList = _data.word_data || []
              let hotWordTotal = 0
              this.hotWordList.map(item => {
                hotWordTotal = hotWordTotal + Number(item.value)
              })
              this.hotWordList.map(item => {
                item.rate = (Number(item.value) / hotWordTotal * 100).toFixed(2)
              })

              this.newWordList = _data.new_word_data || []
              let newWordTotal = 0
              this.newWordList.map(item => {
                newWordTotal = newWordTotal + Number(item.value)
              })
              this.newWordList.map(item => {
                item.rate = (Number(item.value) / newWordTotal * 100).toFixed(2)
              })

              let genderData = _data.gender_data || []
              this.handlePieOptions([
                {value: genderData[0].value, name: genderData[0].dimension == 1 ? '男性' : ''},
                {value: genderData[1].value, name: genderData[1].dimension == 2 ? '女性' : ''},
              ]);

              let ageData = _data.age_data || []
              this.handleBarOptions(ageData);

              let provinceData = _data.province_data || []
              let provinceTotal = 0
              provinceData.map(item => {
                provinceTotal = provinceTotal + Number(item.value)
              })
              provinceData.map(item => {
                item.name = item.dimension
                item.rate = (Number(item.value) / provinceTotal * 100).toFixed(2)
              })
              this.handleMapOptions(provinceData);
              this.provinceList = provinceData

              let brandData = _data.brand_data || []
              brandData.map(item => {
                item.name = item.dimension
              })
              this.handleEquipmentOptions(brandData)

              let activeData = _data.active_data || []
              activeData.map(item => {
                // item.name = item.dimension
                switch(item.dimension) {
                  case '1':
                    item.name = '静默'
                    break;
                  case '2':
                    item.name = '轻度'
                    break;
                  case '3':
                    item.name = '中度'
                    break;
                  case '4':
                    item.name = '重度'
                    break;
                }
              })
              this.handleActiveOptions(activeData)
            } else {
                console.error('获取粉丝画像数据失败')
            }
        });
    },
  }
};
</script>

<style scoped lang="less">
.echart-box {
  width: 100%;
  height: 100%;
  background: #f9f9fa;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.map-box {
  width: 1200px;
  height: 500px;
}

.table-box {
  width: 600px;
}
.table {
  padding: 20px;
  background-color: #f9f9fa;
  .main-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  /deep/ .ant-table-thead > tr > th {
    background-color: #e1e1e1;
  }
}

.table-box /deep/ th.replacecolor {
  background-color: #ccc !important;
}
</style>
