<template>
  <a-modal
    :visible="value"
    title="新建短视频分组"
    :confirmLoading="confirmLoading"
    :mask-closable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 90px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 90px)'}"
    >
      <a-form-model-item label="分组名称" prop="name">
        <a-input
          v-model.trim="form.name"
          allow-clear
          placeholder="请输入分组名称"
          style="width: 300px;"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'AddAwemeGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          name: ''
        }
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}
</style>
