<template>
  <div>
    <div style="display: flex;align-items: center">
      <day-span v-model="daySpan" label="发布时间" @change="search"></day-span>
      <a-button
          type="primary"
          style="margin-left: 20px"
          @click="exportExcel"
          :loading="exportLoading"
      >
        数据导出
      </a-button>
    </div>
    <div style="padding: 30px 0">
      <a-table
          :columns="columns"
          :rowKey="(record) => record.id"
          :data-source="data_list"
          bordered
          :loading="loading"
          :pagination="false"
      >
        <div slot="type" slot-scope="text, record">
          {{ record.type == 1 ? "已收藏" : record.type == 2 ? "未收藏" : "" }}
        </div>
        <div slot="num" slot-scope="text">
          {{ text | changeNum }}
        </div>
        <div slot="operation" slot-scope="text, record">
          <a-button
              type="link"
              @click="handleAction(record)"
          >
            详情
          </a-button>
        </div>
      </a-table>
    </div>
    <div v-if="DetailList.length">
      <a-table
          :columns="Detailcolumns"
          :rowKey="(record) => record.id"
          :data-source="DetailList"
          bordered
          :loading="DetailLoading"
          :pagination="false"
          @change="onTableChange"
      >
        <div slot="index" slot-scope="text, record,index">
          {{ index + 1 }}
        </div>
        <div class="video-info" slot="awemeInfo" slot-scope="text, record">
          <div class="video-info__cover">
            <img :src="record.aweme_cover" alt="">
          </div>
          <div class="video-info__content">
            <p>
              <span class="value">{{ record.aweme_title || "--" }}</span>
            </p>
            <p>
              <span class="label">发布日期：</span>
              <span class="value">{{ record.publish_time }}</span>
            </p>
          </div>
        </div>
      </a-table>

      <!-- 分页配置 -->
      <base-pagination
          :currentPage="pagination.page_num"
          :pageSize="pagination.page_size"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </div>
</template>

<script>
import DaySpan from "@/components/widgets/day-span";
import moment from "moment";
import api from "@/api/DataList";
import uploadExcel from "@/utils/uploadExcel.js";


export default {
  name: "DataList",
  data() {
    return {
      query: {
        start_time: moment(new Date()).add(-7, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: moment(new Date()).add(-1, "days").format("YYYY-MM-DD") + " 23:59:59",
        author_id: undefined
      },
      daySpan: {
        dayOffset: 7,
        dayRange: []
      },
      loading: false,
      exportLoading: false,
      columns: [
        {
          title: "",
          align: "center",
          scopedSlots: {customRender: "type"},
        },
        {
          title: "作品数",
          dataIndex: "aweme_total",
          customRender: this.$dict.humanReadable,
          align: "center"
        },
        {
          title: "总播放量",
          dataIndex: "play_volume",
          align: "center",
          customRender: this.$dict.humanReadable,
        },
        {
          title: "集均播放",
          dataIndex: "avg_play_volume",
          customRender: this.$dict.humanReadable,
          align: "center"
        },
        {
          title: "集均点赞",
          dataIndex: "avg_digg_count",
          customRender: this.$dict.humanReadable,
          align: "center"
        },
        {
          title: "集均评论",
          dataIndex: "avg_comment_count",
          customRender: this.$dict.humanReadable,
          align: "center"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
        },
      ],
      data_list: [],
      DetailLoading: false,
      Detailcolumns: [
        {
          title: "排序",
          align: "center",
          scopedSlots: {customRender: "index"},
        },
        {
          title: "视频",
          dataIndex: "publish_time",
          scopedSlots: {customRender: "awemeInfo"},
          align: "center",
          width: "300",
          sorter: true,
        },
        {
          title: "播放量",
          dataIndex: "play_volume",
          align: "center",
          customRender: this.$dict.humanReadable,
          sorter: true,
        },
        {
          title: "点赞量",
          dataIndex: "digg_count",
          customRender: this.$dict.humanReadable,
          align: "center",
          sorter: true,
        },
        {
          title: "评论量",
          dataIndex: "comment_count",
          customRender: this.$dict.humanReadable,
          align: "center",
          sorter: true,
        },
        {
          title: "转发量",
          dataIndex: "share_count",
          customRender: this.$dict.humanReadable,
          align: "center",
          sorter: true,
        },
        {
          title: "更新时间",
          dataIndex: "mtime",
          align: "center"
        }
      ],
      DetailList: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      row: {},
      sort: "asc",
      order: 5,
    };
  },
  components: {DaySpan},
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  computed: {
    dayQuery() {
      return {
        dateRanges: this.daySpan.dayRange.length ? {
          publishTime: {
            startDate: this.daySpan.dayRange[0].format("YYYY-MM-DD") + " 00:00:00",
            endDate: this.daySpan.dayRange[1].format("YYYY-MM-DD") + " 23:59:59"
          }
        } : undefined,
        dayOffset: this.daySpan.dayRange.length ? undefined : (this.daySpan.dayOffset || 7)
      };
    }
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    search() {
      if(this.daySpan.dayRange.length) {
        this.query.start_time = this.dayQuery.dateRanges.publishTime.startDate;
        this.query.end_time = this.dayQuery.dateRanges.publishTime.endDate;
      } else if(this.dayQuery.dayOffset == 7) {
        this.query.start_time = moment(new Date()).add(-7, "days").format("YYYY-MM-DD") + " 00:00:00";
        this.query.end_time = moment(new Date()).add(-1, "days").format("YYYY-MM-DD") + " 23:59:59";
      } else if(this.dayQuery.dayOffset == 15) {
        this.query.start_time = moment(new Date()).add(-15, "days").format("YYYY-MM-DD") + " 00:00:00";
        this.query.end_time = moment(new Date()).add(-1, "days").format("YYYY-MM-DD") + " 23:59:59";
      } else if(this.dayQuery.dayOffset == 30) {
        this.query.start_time = moment(new Date()).add(-30, "days").format("YYYY-MM-DD") + " 00:00:00";
        this.query.end_time = moment(new Date()).add(-1, "days").format("YYYY-MM-DD") + " 23:59:59";
      }
      this.getStatistics();
    },
    getStatistics() {
      this.loading = true;
      let params = {
        author_id: this.authorId,
        start_time: this.query.start_time,
        end_time: this.query.end_time,
      };
      api.getStatistics(params).then(res => {
        this.loading = false;
        if(res.code == 200) {
          this.data_list = res.data || [];
        } else {
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },
    getAwemeDetail() {
      this.DetailLoading = true;
      let params = {
        author_id: this.authorId,
        type: this.row.type,
        sort: this.sort,
        order: this.order,
        start_time: this.query.start_time,
        end_time: this.query.end_time,
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size,
      };
      api.getAwemeDetail(params).then(res => {
        this.DetailLoading = false;
        if(res.code == 200) {
          this.DetailList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.$message.error(res.message || "获取对比详情失败");
        }
      });
    },//对比详情
    handleAction(row) {
      this.row = row || {};
      this.getAwemeDetail();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.page_num = current;
      this.pagination.page_size = pageSize;
      this.getAwemeDetail();
    },
    onTableChange(pagination, filters, sorter) {
      // console.log(pagination, filters, sorter);
      this.order = sorter.columnKey == "publish_time" ? 5 : sorter.columnKey == "play_volume" ? 1 : sorter.columnKey == "digg_count" ? 2 : sorter.columnKey == "comment_count" ? 3 : sorter.columnKey == "share_count" ? 4 : null;
      this.sort = sorter.order == "ascend" ? "asc" : "desc";
      this.getAwemeDetail();
    },
    exportExcel() {
      let data = Object.assign({
        author_id: this.authorId,
        start_time: this.query.start_time,
        end_time: this.query.end_time,
      });
      let url = "/author/aweme/export";
      return uploadExcel(
          url,
          data,
          "数据对比"
      );
    },// 导出
  }
};
</script>

<style scoped lang="less">
.video-info {
  display: flex;
  width: 100%;

  &__cover {
    position: relative;
    width: 124px;
    height: 96px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    flex: 1;
    padding: 0 10px;
  }

  .duration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 5px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

    span {
      color: #FFF;
      font-size: 14px;
      line-height: 14px;
    }
  }

  p {
    display: flex;

    .value {
      flex: 1;
      text-align: left;
    }
  }
}
</style>
