<template>
  <div class="type-date-range-picker">
    <a-button-group>
      <a-button
        v-for="item in typeList"
        :key="item.value"
        :type="item.value === value[0] ? 'primary' : 'default'"
        style="padding: 0 8px"
        @click="handleTypeChange(item.value)"
      >
        <span v-if="terraceType == 'live' && item.value == 7">7天内</span>
        <span v-else-if="terraceType == 'live' && item.value == 30">30天内</span>
        <span v-else>
          {{ item.label }}
        </span>
      </a-button>
    </a-button-group>
    <a-select v-model="myType" style="width: 75px; margin-left: 10px" @select="handleSelect">
      <a-select-option :value="1"> 按日 </a-select-option>
      <a-select-option :value="2"> 按月 </a-select-option>
    </a-select>
    <a-range-picker
      v-if="myType == 1"
      v-model="value[1]"
      :allow-clear="allowClear"
      format="YYYY-MM-DD"
      @change="handleChange"
    />
    <a-month-picker
      format="YYYY-MM"
      :disabled-date="disabledDate"
      v-if="myType == 2"
      v-model="searchMonthTime"
      @change="handleChange"
    />
  </div>
</template>

<script>
const typeList = [
  { label: '今天', value: 0 },
  { label: '昨天', value: 1 },
  { label: '近7天', value: 7 },
  { label: '近30天', value: 30 },
  { label: '本月', value: 2 },
  { label: '上月', value: 3 },
];

export default {
  name: 'TypeDateRangePicker',
  props: {
    value: {
      // ['', []]
      type: Array,
      required: true,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },

    terraceType: {
      type: String,
      default: '',
    },
    typeList: {
      type: Array,
      default() {
        return typeList.map((item) => {
          return { ...item };
        });
      },
    },
  },
  data() {
    return {
      searchMonthTime: '',
      myType: 1,
    };
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.$moment().endOf('day');
    },
    handleSelect(val) {
      this.value[0] = '';
      this.value[1] = [];
      this.searchMonthTime = '';
    },
    handleTypeChange(type) {
      if (type === this.value[0]) {
        if (!this.allowClear) return;

        this.$emit('input', ['', []]);
        this.$emit('change', ['', []]);
      } else {
        let startDate;
        let endDate;
        switch (type) {
          case 0:
            startDate = this.$moment().format('YYYY-MM-DD');
            endDate = this.$moment().format('YYYY-MM-DD');
            this.myType = 1;
            break;
          case 1:
            startDate = this.$moment().subtract(type, 'days').format('YYYY-MM-DD');
            endDate = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.myType = 1;
            break;
          case 7:
          case 30:
            startDate =
              this.terraceType == 'live'
                ? this.$moment()
                    .subtract(type - 1, 'days')
                    .format('YYYY-MM-DD')
                : this.$moment().subtract(type, 'days').format('YYYY-MM-DD');
            endDate =
              this.terraceType == 'live'
                ? this.$moment().format('YYYY-MM-DD')
                : this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.myType = 1;
            break;
          case 2:
            startDate = this.$moment().subtract(0, 'month').startOf('month').format('YYYY-MM-DD');
            endDate = this.$moment().subtract(0, 'month').endOf('month').format('YYYY-MM-DD');
            this.myType = 2;
            this.searchMonthTime = this.$moment().subtract(0, 'months');
            break;
          case 3:
            startDate = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            endDate = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            this.myType = 2;
            this.searchMonthTime = this.$moment().subtract(1, 'months');
            break;
        }

        if (type == 2) {
          this.$emit('input', [type, [startDate, endDate], 0]);
          this.$emit('change', [type, [startDate, endDate], 0]);
        } else if (type == 3) {
          this.$emit('input', [type, [startDate, endDate], 1]);
          this.$emit('change', [type, [startDate, endDate], 1]);
        } else {
          this.$emit('input', [type, [startDate, endDate]]);
          this.$emit('change', [type, [startDate, endDate]]);
        }
      }
    },
    // 中国时间转化为标准日期 p为不够10添加0的函数
    p(s) {
      return s < 10 ? '0' + s : s;
    },
    handleChange(val) {
      let arr = [];
      if (this.myType == 1) {
        val.forEach((item) => {
          const d = new Date(item);
          const resDate = d.getFullYear() + '-' + this.p(d.getMonth() + 1) + '-' + this.p(d.getDate());
          arr.push(resDate);
        });
      } else {
        // const d = new Date(val);
        // const nextMonth = this.$moment(val).endOf('month').format('YYYY-MM-DD')
        const resDate = this.$moment(val).startOf('month').format('YYYY-MM-DD');
        const resEndDate = this.$moment(val).endOf('month').format('YYYY-MM-DD');
        arr.push(resDate);
        arr.push(resEndDate);
      }
      console.log(arr, '777');
      // let arr = []
      // arr.push(val)
      // console.log(arr,'333')
      this.value[0] = '';
      this.$emit('input', ['', arr]), this.$emit('change', ['', arr]);
    },
  },
};
</script>

<style lang="less" scoped>
.type-date-range-picker {
  display: flex;
  align-items: center;
  height: 40px;

  .ant-btn-group {
    margin-right: 8px;
  }

  .ant-calendar-picker {
    flex: 1;
  }
}
</style>
