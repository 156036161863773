<template>
  <a-modal
    :visible="value"
    title="收藏视频"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 90px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 90px)'}"
    >
      <a-form-model-item label="选择分组" prop="groupIds">
        <a-checkbox-group v-model="form.groupIds">
          <div class="group-wrap" v-for="item in groupList" :key="item.groupId" style="height: 40px;">
            <a-checkbox :value="item.groupId">
              <span>{{ item.groupName }}</span>
            </a-checkbox>
          </div>
        </a-checkbox-group>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div class="btn-wrap">
        <div class="btn-wrap__left">
          <a-button class="btn-link" type="link" @click="handleAddGroup">
            新建分组
          </a-button>
        </div>
        <div class="btn-wrap__right">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'JoinAuthorGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          awemeId: '',
          groupIds: []
        }
      }
    },
    groupList: {
      type: Array,
      default() {
        return []
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        groupIds: [
          { type: 'array', required: true, message: '请至少选择一个分组', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    handleAddGroup() {
      this.$emit('add-group')
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      }) 
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.group-wrap {
  display: flex;
  align-items: center;
  height: 40px;
}

.btn-wrap {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }

  .btn-link {
    padding: 0;
  }
}
</style>
