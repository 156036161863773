<template>
  <div class="page">
    <a-row class="mb-24" type="flex">
      <a-col flex="1 0 0">
        <a-form-model ref="searchForm" :model="searchForm" layout="inline">
          <a-form-model-item label="发布时间" prop="dateRange">
            <TypeDateRangePicker :terraceType="terraceType" v-model="searchForm.dateRange" @change="handleDateRangeChange" />
          </a-form-model-item>
        </a-form-model>
        <!-- <day-span v-model="daySpan" label="发布时间" @change="search"></day-span> -->
      </a-col>
      <a-col>
        <a-space>
          <a-button type="primary" @click="onCompare"> 作品对比 </a-button>
          <a-button type="primary" :loading="exportLoading" @click="handleExport"> 导出 </a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-row class="mb-16" type="flex">
      <a-col flex="1 0 0">
        <div class="aweme-statistics-data">
          <div class="data-item">
            <div class="data-item__label">作品数</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.totalCount >= 10000">
                  {{ statisticsData.totalCount }}
                </template>
                {{ statisticsData.totalCount | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.totalCount) >= 10000">
                  {{ compareData.totalCount }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.totalCount && compareData.totalCount !== null">
                  (<template v-if="compareData.totalCount > 0">+</template>{{ compareData.totalCount | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">模板作品</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.moduleAwemeCount >= 10000">
                  {{ statisticsData.moduleAwemeCount }}
                </template>
                {{ statisticsData.moduleAwemeCount | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.moduleAwemeCount) >= 10000">
                  {{ compareData.moduleAwemeCount }}
                </template>
                <span
                  class="data-item__spanTwo"
                  v-if="compareData.moduleAwemeCount && compareData.moduleAwemeCount !== null"
                >
                  (<template v-if="compareData.moduleAwemeCount > 0">+</template
                  >{{ compareData.moduleAwemeCount | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">发布频率</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.frequency >= 10000">
                  {{ statisticsData.frequency }}
                </template>
                {{ statisticsData.frequency | humanReadable }}
              </a-tooltip>
              <span class="data-item__spanTwo" v-if="statisticsData.frequency && statisticsData.frequency !== null">
                天/次
              </span>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">总播放量</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.totalPlayVolume >= 10000">
                  {{ statisticsData.totalPlayVolume }}
                </template>
                {{ statisticsData.totalPlayVolume | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.totalPlayVolume) >= 10000">
                  {{ compareData.totalPlayVolume }}
                </template>
                <span
                  class="data-item__spanTwo"
                  v-if="compareData.totalPlayVolume && compareData.totalPlayVolume !== null"
                >
                  (<template v-if="compareData.totalPlayVolume > 0">+</template
                  >{{ compareData.totalPlayVolume | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">集均播放</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgPlayVolume >= 10000">
                  {{ statisticsData.avgPlayVolume }}
                </template>
                {{ statisticsData.avgPlayVolume | humanReadable }}
              </a-tooltip>
              <a-tooltip>
                <template slot="title" v-if="Math.abs(compareData.avgPlayVolume) >= 10000">
                  {{ compareData.avgPlayVolume }}
                </template>
                <span class="data-item__spanTwo" v-if="compareData.avgPlayVolume && compareData.avgPlayVolume !== null">
                  (<template v-if="compareData.avgPlayVolume > 0">+</template
                  >{{ compareData.avgPlayVolume | humanReadable }})
                </span>
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">集均点赞</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgDiggCount >= 10000">
                  {{ statisticsData.avgDiggCount }}
                </template>
                {{ statisticsData.avgDiggCount | humanReadable }}
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">集均评论</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.avgCommentCount >= 10000">
                  {{ statisticsData.avgCommentCount }}
                </template>
                {{ statisticsData.avgCommentCount | humanReadable }}
              </a-tooltip>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">增粉量</div>
            <div class="data-item__value">
              <a-tooltip>
                <template slot="title" v-if="statisticsData.fansTotalAddCount >= 10000">
                  {{ statisticsData.fansTotalAddCount }}
                </template>
                {{ statisticsData.fansTotalAddCount | humanReadable }}
              </a-tooltip>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <AwemeListTable
      :loading="loading"
      :data="awemeList"
      :pagination="pagination"
      @table-change="handleTableChange"
      @pagination-change="handlePaginationChange"
      @operate="handleOperate"
    />

    <CollectAwemeModal
      ref="collectAwemeModal"
      v-model="collectAwemeVisible"
      :form="collectAwemeForm"
      :group-list="collectAwemeGroupList"
      :confirm-loading="collectAwemeLoading"
      @add-group="handleAddAwemeGroup"
      @confirm="handleCollectAwemeConfirm"
    />

    <AddAwemeGroupModal
      ref="addAwemeGroupModal"
      v-model="addAwemeGroupVisible"
      :form="addAwemeGroupForm"
      :confirm-loading="addAwemeGroupLoading"
      @confirm="handleAddAwemeGroupConfirm"
    />

    <!-- 作品对比 -->
    <a-modal v-model="compareVisible" title="作品对比" :footer="null" width="800px" :zIndex="10000">
      <div class="compare-wrap">
        <a-tabs v-model="compareActiveTab" @change="onTabChange" id="gaManageTab">
          <a-tab-pane key="1" tab="播放量"></a-tab-pane>
          <a-tab-pane key="2" tab="完播率"></a-tab-pane>
          <a-tab-pane key="3" tab="点赞量"></a-tab-pane>
          <a-tab-pane key="4" tab="分享量"></a-tab-pane>
          <a-tab-pane key="5" tab="评论量"></a-tab-pane>
          <a-tab-pane key="6" tab="吸粉量"></a-tab-pane>
        </a-tabs>
        <div class="chart">
          <BaseCharts
            ref="BaseCharts"
            key="bar"
            :style="{ width: '100%', height: '400px' }"
            :option="chartCompareData"
          />
          <div class="bottom-box">
            <div class="left">
              <div class="item item-1">表现最佳作品</div>
              <div class="item item-2">表现最差作品</div>
            </div>
            <div class="right">
              <span style="color: #fe496d">--</span> 同类型作品平均线
              <span style="font-weight: bold">{{ compareMedian }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import TypeDateRangePicker from '@/components/TypeDateRangePicker';
import AwemeListTable from './components/AwemeListTable';
import CollectAwemeModal from '@/components/CollectAwemeModal';
import AddAwemeGroupModal from '@/components/AddAwemeGroupModal';
import BaseCharts from '@/components/BaseChart';
import awemeApi from '@/api/aweme';

const defaultQuery = {
  sort: 'DESC',
  sort_field: 1,
  begin_time: '',
  end_time: '',
  author_id: undefined,
};
const defaultStatQuery = {
  dayOffsetField: 'publishTime',
  dayOffset: undefined,
  dateRanges: {
    publishTime: {
      startDate: undefined,
      endDate: undefined,
    },
  },
  param: {
    authorId: undefined,
  },
  groupBy: undefined,
  start: 0,
  limit: 100,
};
const barData = {
  color: ['#ccc'], // 自定义颜色
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      lineStyle: {
        type: 'solid',
        color: 'rgba(255, 255, 255, .1)',
        shadowColor: 'transparent',
        shadowBlur: 0,
      },
    },
    backgroundColor: 'none',
    borderWidth: '0',
    padding: 0,
    formatter: function (params, ticket, callback) {
      let _params = params[0];

      return `
        <div style="padding: 10px; min-width: 150px; background: #fff;border-radius: 8px;box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);">
            <div style="padding-bottom:10px; font-size: 12px; color: #999; line-height: 1;">发布时间 ${_params.axisValue}</div>
            <div style="font-size: 12px; color: #000; line-height: 1;">播放量 ${_params.data}</div>
        </div>
      `;
    },
  },
  grid: {
    left: '5%',
    right: '4%',
    bottom: '15%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      axisLine: { show: false },
      axisTick: {
        show: false,
        alignWithLabel: false, // x轴是否与柱子对齐
      },
      axisLabel: {
        show: true,
        fontSize: 14,
      },
      boundaryGap: [10, 10],
    },
  ],
  yAxis: [
    {
      type: 'value',
      name: '',
      axisLine: { show: false }, // y轴不显示线
      axisTick: {
        show: false, // 不显示y轴刻度
      },
      axisLabel: {
        color: '#666',
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#eee',
        },
      },
    },
  ],
  series: [
    {
      type: 'bar',
      barWidth: 40,
      // z: 2
    },
    {
      type: 'line',
      lineStyle: {
        type: 'dashed',
        color: '#fe496d',
      },
      symbol: 'none',
      // z: 1
    },
  ],
};

const sortField = {
  playVolume: 1,
  diggCount: 2,
  commentCount: 3,
  shareCount: 4,
  publishTime: 5,
  playTotalCountCompletionRate: 6,
  playTotalCountAvgTime: 7,
  fansTotalAdd: 8,
};

export default {
  components: {
    TypeDateRangePicker,
    AwemeListTable,
    CollectAwemeModal,
    AddAwemeGroupModal,
    BaseCharts,
  },
  props: {
    platform: {
      type: Number,
      required: true,
    },
    authorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      terraceType:'aweme',
      query: _.cloneDeep(defaultQuery),
      loading: false,
      searchForm: {
        dateRange: [
          30,
          [
            this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
            this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
          ]
        ],
      },
      statisticsData: {},
      compareData: {},
      sorter: {
        order: 'descend',
        field: 'publishTime',
      },
      loading: false,
      awemeList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      exportLoading: false,
      collectAwemeVisible: false,
      collectAwemeForm: {
        awemeId: '',
        groupIds: [],
      },
      collectAwemeGroupList: [],
      collectAwemeLoading: false,
      addAwemeGroupVisible: false,
      addAwemeGroupForm: {
        name: '',
      },
      addAwemeGroupLoading: false,
      compareVisible: false,
      compareActiveTab: '1',
      chartCompareData: {},
      compareMedianData: {},
      awemeCompareData: [],
      compareMedian: 0,
    };
  },
  mounted() {
    this.$on('init', this.init);
  },
  methods: {
    handleDateRangeChange(val) {
      if (val[1].length > 0) {
        this.init();
      }
    },
    init(options = {}) {
      // this.search();
      this.getAwemeCompareData();
      this.fetchAuthorAwemeStatistics();
      this.fetchAuthorAwemeStatisticsList();
    },
    async fetchAuthorAwemeStatistics() {
      try {
        const { dateRange } = this.searchForm;
        const params = {
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
          this.$set(params,'monthOffset',dateRange[2])
        }
        const { code, data, message } = await awemeApi.fetchAuthorAwemeStatistics(params);

        if (code === 200) {
          this.statisticsData = data.statisticsData || {};
          this.compareData = data.compareData || {};
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleTableChange(pagination, filters, sorter, { currentDataSource }) {
      const { order, field } = sorter;

      this.sorter = { order, field };
      this.pagination.page = 1;
      this.fetchAuthorAwemeStatisticsList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchAuthorAwemeStatisticsList();
    },
    async fetchAuthorAwemeStatisticsList() {
      this.loading = true;
      try {
        const { dateRange } = this.searchForm;
        const { order, field } = this.sorter;
        const { page, size } = this.pagination;
        const params = {
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
          sort: order === 'ascend' ? 'ASC' : 'DESC',
          sortField: sortField[field],
          page,
          size,
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
        }
        const { code, data, message } = await awemeApi.fetchAuthorAwemeStatisticsList(params);

        this.loading = false;

        if (code === 200) {
          this.awemeList = (data.list || []).map((item) => {
            return { ...item, platform: Number(this.platform) };
          });
          this.pagination.total = data.total || 0;
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async handleExport() {
      this.exportLoading = true;
      try {
        const { dateRange } = this.searchForm;
        const { order, field } = this.sorter;
        const params = {
          platformType: this.platform,
          authorId: this.authorId,
          dayOffset: dateRange[0],
          startTime: `${dateRange[1][0]} 00:00:00`,
          endTime: `${dateRange[1][1]} 23:59:59`,
          sort: order === 'ascend' ? 'ASC' : 'DESC',
          sortField: sortField[field],
        };
        if (dateRange[0] == 2 || dateRange[0] == 3) {
          delete params.dayOffset;
        }
        await awemeApi.exportAuthorAwemeList(params);
        this.exportLoading = false;
      } catch (e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    handleOperate(type, record) {
      type === 'collectAweme' && this.openCollectAwemeModal(record.awemeId);
    },
    openCollectAwemeModal(awemeId) {
      this.collectAwemeVisible = true;
      this.collectAwemeForm.awemeId = awemeId;
      this.fetchMyAwemeGroupList();
    },
    // 获取我的短视频分组列表
    async fetchMyAwemeGroupList() {
      try {
        const { code, data, message } = await awemeApi.fetchMyAwemeGroupList();

        if (code === 200) {
          this.collectAwemeGroupList = data.map((item) => {
            return { groupId: item.id, groupName: item.name };
          });
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleCollectAwemeConfirm() {
      this.collectAweme();
    },
    // 收藏短视频
    async collectAweme() {
      this.collectAwemeLoading = true;
      try {
        const { awemeId, groupIds } = this.collectAwemeForm;
        const params = {
          awemeId,
          groupId: groupIds,
        };
        const { code, message } = await awemeApi.collectAweme(params);

        this.collectAwemeLoading = false;

        if (code === 200) {
          this.$message.success('收藏视频成功');
          this.collectAwemeVisible = false;
          this.$refs.collectAwemeModal.resetForm();
          this.fetchAuthorAwemeStatisticsList();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.collectAwemeLoading = false;
      }
    },
    handleAddAwemeGroup() {
      this.addAwemeGroupVisible = true;
    },
    handleAddAwemeGroupConfirm() {
      this.addAwemeGroup();
    },
    // 新建短视频分组
    async addAwemeGroup() {
      this.addAwemeGroupLoading = true;
      try {
        const params = {
          ...this.addAwemeGroupForm,
        };
        const { code, message } = await awemeApi.addAwemeGroup(params);

        this.addAwemeGroupLoading = false;

        if (code === 200) {
          this.$message.success('新建分组成功');
          this.addAwemeGroupVisible = false;
          this.$refs.addAwemeGroupModal.resetForm();
          this.fetchMyAwemeGroupList();
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        console.error(e);
        this.addAwemeGroupLoading = false;
      }
    },
    onCompare() {
      this.compareVisible = true;
    },
    onTabChange(val) {
      switch (val) {
        case '1':
          this.compareMedian = this.formatNum(this.compareMedianData.play_base_value);
          break;
        case '2':
          this.compareMedian = `${
            this.compareMedianData.play_finish_base_value
              ? (this.compareMedianData.play_finish_base_value * 100).toFixed(2)
              : 0
          }%`;
          break;
        case '3':
          this.compareMedian = this.formatNum(this.compareMedianData.like_base_value);
          break;
        case '4':
          this.compareMedian = this.formatNum(this.compareMedianData.share_base_value);
          break;
        case '5':
          this.compareMedian = this.formatNum(this.compareMedianData.comment_base_value);
          break;
        case '6':
          this.compareMedian = this.formatNum(this.compareMedianData.get_fans_base_value);
          break;
      }
      this.handleBarOptions(val);
    },
    formatNum(val) {
      return `${val ? this.filtrate.changeNum(Math.round(val), 0) : 0}`;
    },
    getAwemeCompareData() {
      this.$api.awemeAnalysis.awemeCompare({ author_id: this.authorId }).then((res) => {
        if (res.code == 200) {
          let _data = res.data;
          this.compareMedianData = _data;
          this.compareMedian = this.formatNum(_data.play_base_value);
          this.awemeCompareList();
        } else {
          console.error('获取作品对比数据失败');
        }
      });
    },
    awemeCompareList() {
      this.$api.awemeAnalysis.awemeCompareList({ author_id: this.authorId }).then((res) => {
        if (res.code == 200) {
          this.awemeCompareData = res.data;
          this.handleBarOptions('1');
        } else {
          console.error('获取作品对比数据失败');
        }
      });
    },
    handleBarOptions(type) {
      let xAxisData = [];
      let yAxisData = [];
      let yAxisData1 = [];
      let _data = this.awemeCompareData;

      switch (type) {
        case '1':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.play_cnt);
            yAxisData1.push(this.compareMedian);
          });
          break;
        case '2':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.finish_ratio ? item.finish_ratio.toFixed(4) : 0);
            yAxisData1.push(this.compareMedian);
          });
          break;
        case '3':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.like_cnt);
            yAxisData1.push(this.compareMedian);
          });
          break;
        case '4':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.share_cnt);
            yAxisData1.push(this.compareMedian);
          });
          break;
        case '5':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.comment_cnt);
            yAxisData1.push(this.compareMedian);
          });
          break;
        case '6':
          _data.map((item) => {
            xAxisData.push(item.create_time);
            yAxisData.push(item.create_fans_cnt);
            yAxisData1.push(this.compareMedian);
          });
          break;
      }

      let max = Math.max(...yAxisData);
      let min = Math.min(...yAxisData);

      let rich = {
        value: {
          align: 'center',
        },
      };
      // let xAxisData = ["1", "24", "31", "41", "50"]
      _data.map((item, index) => {
        rich[`c_${index}`] = {
          width: 40,
          height: 55,
          align: 'center',
          backgroundColor: {
            image: `${item.cover}`,
            // image: 'https://p3.douyinpic.com/aweme/1080x1080/aweme-avatar/mosaic-legacy_30f0700012b178f2167b5.webp?from=2956013662'
          },
          borderRadius: 5,
        };
      });

      let that = this;
      let obj = {
        xAxis: [
          {
            data: xAxisData,
            axisLabel: {
              formatter: function (value, index) {
                return '{c_' + index + '|}';
              },
              rich: rich,
            },
          },
        ],
        series: [
          {
            data: yAxisData,
            itemStyle: {
              barBorderRadius: [5, 5, 0, 0],
              color: function (param) {
                return param.value == max ? '#fe496d' : param.value == min ? '#999' : '#ccc';
              },
            },
            label: {
              show: true,
              precision: 1,
              position: 'top',
              valueAnimation: true,
              formatter: function (params, ticket, callback) {
                let val = params.data;
                if (that.compareActiveTab == 2) {
                  val = `${val ? (val * 100).toFixed(2) : 0}%`;
                }
                return val;
              },
            },
          },
          {
            data: yAxisData1,
          },
        ],
      };
      this.chartCompareData = _.merge(_.cloneDeep(barData), obj);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .aweme-statistics-data {
    display: flex;

    .data-item {
      display: flex;
      flex-flow: column-reverse;
      margin: 0 16px 0 0;
      min-width: 60px;

      &__label {
        margin: 4px 0 0;
        color: rgba(#000, 0.45);
        font-size: 14px;
        text-align: center;
      }

      &__value {
        color: rgba(#000, 0.85);
        font-size: 24px;
        font-weight: bold;
        text-align: center;

        span {
          font-size: 24px;
          &.data-item__spanTwo {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.a-tables-scroll /deep/ .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ul::-webkit-scrollbar {
    // 整个滚动条.
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    // 滚动条上的滚动滑块
    border-radius: 8px;
    background-color: #7f7f7f;
  }

  &::-webkit-scrollbar-corner {
    // 当同时有垂直滚动条和水平滚动条时交汇的部分
    background_color: #242832;
  }

  &::-webkit-scrollbar-track {
    // 滚动条轨道
    border-radius: 0;
    background_color: #242832;
  }
}

.chart {
  padding: 20px 20px 10px 20px;
  background-color: rgba(149, 184, 209, 0.1);
  border-radius: 10px;

  .bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 20px;
    font-size: 12px;
    color: #999;

    .left {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &-1::before {
          background-color: #fe496d;
        }

        &-2::before {
          background-color: #999;
        }

        &::before {
          content: '';
          margin-right: 3px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
